export const itemRarity = {
  1: "Common",
  2: "Rare",
  3: "Epic",
  4: "Legendary",
  99: "None",
};

export const itemRarityLowercase = {
  1: "common",
  2: "rare",
  3: "epic",
  4: "legendary",
  99: "None",
};
export const default_currency = "USD";
export const default_store_section = "promos";
export const game_name = "War and Peace";

// API routes
export const login_route = "/api/auth/login";
export const get_store_packs_route = "/api/store/get-packs";
export const buy_pack_route = "/api/store/init-payment";
export const log_metric_route = "/api/stats";
export const claim_gift_route = "/api/store/claim-system-message";
export const collect_daily_subscription_route =
  "/api/store/collect-daily-subscription";
export const logout_route = "/api/auth/logout";
export const redeem_coupon_route = "/api/store/validate-coupon";
export const claim_gold_rush_route = "/api/store/claim-event-prize";
export const search_user_route = "/api/store/search-user";
export const spin_for_discount_route = "/api/store/spin-for-discount";
export const get_notifications_settings_route = "/api/store/get-notifications-settings";
export const update_notifications_settings_route = "/api/store/update-notifications-settings";
export const get_config = "/api/store/get-config";
export const refresh_notifications_token = "/api/store/refresh-notifications-token";

export const amazon_pack_image_url =
  "https://s3.us-east-2.amazonaws.com/wap.static/web/packs";
export const amazon_language_files_url =
  "https://s3.us-east-2.amazonaws.com/wap.static/web/i18n/";
