import {log_metric_route} from './constants';
import {analytics, logEvent} from './notifications.js';

export const logMetric = async (value) => {
    try {

        const level = 'info'; // Possible values: 'debug', 'info', 'warning', 'error', 'critical'
        Rollbar.captureEvent(value, level);
        logEvent(analytics, JSON.stringify(value));
        
        const response = await fetch(log_metric_route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(value)
        });

        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            Rollbar.error(`An error has occured: ${response.status}`);
            throw new Error(message);
        }

        return await response.text();

    } catch (error) {
        Rollbar.error("Log metric error: " + error);
    }
};

export {analytics, logEvent };
