<script>
    import {_, number, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;
    export let globals;
    export let itemDetails = {};

    function getChestDetails() {
        let chestDetails = {};
        let chestType = gameItem.type;

        if (chestType === undefined) {
            chestDetails.title = $_('ResourcesPackItem');
            chestDetails.description = $_('ResourcesPackItemDetails');
            chestDetails.icon = 'resource_pack_l';

        } else {
            if (chestType === 'resourcesPack') {
                chestDetails.title = $_('ResourcesPackItem');
                chestDetails.description = $_('ResourcesPackItemDetails');
                chestDetails.icon = 'resource_pack_l';
            } else if (chestType === 'holidayPack') {
                chestDetails.title = $_('HolidayPackTitle');
                chestDetails.description = $_('HolidayPackDescription');
                chestDetails.icon = 'resource_pack_special';
            } else if (chestType === 'easterChest') {
                chestDetails.title = $_('EasterChestTitle');
                chestDetails.description = $_('EasterChestkDescription');
                chestDetails.icon = 'chest_easter';
            } else if (chestType === 'resourcesPack1') {
                chestDetails.title = $_('ResourcePack1Title');
                chestDetails.description = $_('ResourcePack1Description');
                chestDetails.icon = 'wagon-resources';
            } else if (chestType === 'resourcesPack2') {
                chestDetails.title = $_('ResourcePack2Title');
                chestDetails.description = $_('ResourcePack2Description');
                chestDetails.icon = 'wagon-resources';
            } else if (chestType === 'resourcesPack3') {
                chestDetails.title = $_('ResourcePack3Title');
                chestDetails.description = $_('ResourcePack3Description');
                chestDetails.icon = 'wagon-resources';
            } else if (chestType === 'speedUpPack1') {
                chestDetails.title = $_('SpeedUpPack1Title');
                chestDetails.description = $_('SpeedUpPack1Description');
                chestDetails.icon = 'wagon-speedups';
            } else if (chestType === 'speedUpPack2') {
                chestDetails.title = $_('SpeedUpPack2Title');
                chestDetails.description = $_('SpeedUpPack2Description');
                chestDetails.icon = 'wagon-speedups';
            } else if (chestType === 'speedUpPack3') {
                chestDetails.title = $_('SpeedUpPack3Title');
                chestDetails.description = $_('SpeedUpPack3Description');
                chestDetails.icon = 'wagon-speedups';
            } else if (
                chestType.includes('equipment') ||
                chestType.includes('xmasGear') ||
                chestType.includes('chinese') ||
                chestType.includes('easter') ||
                chestType.includes('genericshopGear') ||
                chestType.includes('halloweenGearChest') ||
                chestType.includes('superWonderGearChest') ||
                chestType.includes('blackFridayGearChest') ||
                chestType.includes('rallyGearChest')
            ) {
                chestDetails.title = $_(chestType + 'Title');
                chestDetails.description = $_('EquipmentChestDescription', {
                    values: {EQUIPMENT_AMOUNT: gameItem.amount},
                });

                if (chestType.includes('equipmentChestSmall')) {
                    chestDetails.icon = 'equipmentChest1';
                } else if (chestType.includes('equipmentChestMedium')) {
                    chestDetails.icon = 'equipmentChest2';
                } else if (chestType.includes('equipmentChestConstruction')) {
                    chestDetails.icon = 'item_construction_chest';
                } else if (chestType.includes('xmasGear')) {
                    chestDetails.icon = 'equipmentChestXmasEquipment';
                } else if (chestType.includes('chinese')) {
                    chestDetails.icon = 'shop_event_chest_chinese';
                } else if (chestType.includes('easter')) {
                    chestDetails.icon = 'shop_event_chest_easter';
                } else if (chestType.includes('superWonderGearChest')) {
                    chestDetails.icon = 'super_wonder_chest';
                } else if (chestType.includes('blackFridayGearChest')) {
                    chestDetails.icon = 'black_friday_gear_chest';
                } else if (chestType.includes('rallyGearChest')) {
                    chestDetails.icon = 'rallyGearChest';
                } else {
                    chestDetails.icon = 'equipmentChest3';
                }
            } else if (
                chestType.includes('supplies') ||
                chestType.includes('fourthofjuly') ||
                chestType.includes('genericshopSupplies') ||
                chestType.includes('halloween') ||
                chestType.includes('super') ||
                chestType.includes('christmas')
            ) {
                chestDetails.title = $_(chestType + 'Title');
                chestDetails.description = $_('SuppliesChestDescription', {
                    values: {SUPPLIES_AMOUNT: gameItem.amount},
                });

                if (
                    chestType.includes('suppliesChestSmall') ||
                    chestType.includes('genericshopSuppliesChest')
                ) {
                    chestDetails.icon = 'supplies_small_chest';
                } else if (chestType.includes('suppliesChestMedium')) {
                    chestDetails.icon = 'supplies_medium_chest';
                } else if (chestType.includes('suppliesChestLarge')) {
                    chestDetails.icon = 'supplies_large_chest';
                } else if (chestType.includes('fourthofjuly')) {
                    chestDetails.icon = 'shop_event_chest_fourthofjuly';
                } else if (chestType.includes('halloween')) {
                    chestDetails.icon = 'shop_event_chest_halloween_supplies';
                } else if (chestType.includes('christmas')) {
                    chestDetails.icon = 'shop_event_chest_christmas';
                } else if (chestType.includes('super')) {
                    chestDetails.icon = 'shop_event_chest_superwonder_supplies';
                } else {
                    chestDetails.icon = 'equipmentChest1';
                }
            } else if (chestType.includes('commanderPartsChest')) {
                chestDetails.title = $_(chestType + 'Title');
                chestDetails.description = $_(chestType + 'Description');

                if (chestType.includes('commanderPartsChestSmall')) {
                    chestDetails.icon = 'equipmentChest1';
                } else if (chestType.includes('commanderPartsChestMedium')) {
                    chestDetails.icon = 'equipmentChest2';
                } else if (chestType.includes('commanderPartsChestLarge')) {
                    chestDetails.icon = 'equipmentChest3';
                } else {
                    chestDetails.icon = 'equipmentChest1';
                }
            }
        }


        return chestDetails;
    }

    function setItemDetails() {
        if (
            gameItem.category == 'suppliesChest' ||
            gameItem.category == 'equipmentChest' ||
            gameItem.category == 'chest'
        ) {
            const chestDetails = getChestDetails();
            itemDetails.title = chestDetails.title;
            itemDetails.description = chestDetails.description;
            itemDetails.icon = chestDetails.icon;
        } else {
            itemDetails.title = `${$number(gameItem.quantity)} ${$_(
                globals.resourceDetails[gameItem.resourceType].name,
            )}`;
            itemDetails.description = `${$_('ItemsResourceDescription', {
                values: {
                    AMOUNT: $number(gameItem.quantity),
                    RESOURCE: $_(globals.resourceDetails[gameItem.resourceType].name),
                },
            })}`;
            itemDetails.icon = globals.resourceDetails[gameItem.resourceType].icon;
        }

        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
