<script>
  import { _ } from 'svelte-i18n';
  import PackListing from '../PackListing.svelte';
  import { onMount } from 'svelte';

  onMount(() => {
    window.scrollTo(0, 0);
  });
</script>

<PackListing
  storeSection="subscriptions"
  title={$_('NewShopTabSubscriptions')}
/>
