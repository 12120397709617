<script>
    import Crumb from "./Crumb.svelte";
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';

    let hashValue = window.location.hash.substring(1);
    const dispatch = createEventDispatcher();

    function updateHash() {
        hashValue = window.location.hash.substring(1);
        dispatch('hashchange', hashValue);
    }

    onMount(() => {
        window.addEventListener('hashchange', updateHash);
    });

    onDestroy(() => {
        window.removeEventListener('hashchange', updateHash);
    });
    export let crumbs = [];
</script>

<div class="breadcrumbs">
    <ul>
        {#each crumbs as crumb, i}
            <Crumb crumb={crumb} isLast={i === crumbs.length - 1}/>
        {/each}
    </ul>
</div>

<style lang="scss">
  :global {
    .breadcrumbs {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: rgba(36, 13, 7, 0.5);
        list-style-type: none;
        width: 876px;
        height: 40px;
      }
    }
  }
</style>
