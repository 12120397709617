<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_('SupplyItem' + gameItem.supplyId);
        itemDetails.description = $_('SupplyItem' + gameItem.supplyId);
        itemDetails.icon = 'commander_supplies_' + gameItem.supplyId;
        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
