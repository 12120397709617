import './i18n';
import chota from 'chota';
import './app.css'
import App from './App.svelte'
import './modernizr-custom.js'

const app = new App({
  target: document.body
})

export default app