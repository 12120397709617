<script>
    import {_, number, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_(gameItem.category + 'ItemTitle', {
            values: {POINTS: $number(gameItem.amount)},
        });
        itemDetails.description = $_(gameItem.category + 'ItemDescription', {
            values: {POINTS: $number(gameItem.amount)},
        });
        itemDetails.amount = item.amount;
        itemDetails.icon = 'stamina';
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
