<script>
    import { onMount } from 'svelte';
    import {_, isLoading} from 'svelte-i18n';
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faGift, faMegaphone} from '@fortawesome/pro-duotone-svg-icons';
    import {faInstagram} from '@fortawesome/free-brands-svg-icons';
    import {Button, Details} from 'svelte-chota';
    import {game_name} from './constants';
    import {Splide, SplideSlide} from '@splidejs/svelte-splide';
    import {Lightbox} from 'svelte-lightbox';
    import '@splidejs/svelte-splide/css';
    import {logMetric} from './metrics';
    import {isRedirected} from "./stores.js";
    import Loader from "./lib/Loader.svelte";

    let whats_new_modal_open = false;
    function scrollIntoView(target) {
        const el = document.querySelector(target);
        if (!el) return;
        el.scrollIntoView({
            behavior: 'smooth'
        });
    }

    onMount(async () => {
        const feed = new Instafeed({
            accessToken: 'IGQWRQMk1lVmNzTWNZAOEFVS1dFdF9vdVRrcXZAGS0dVV3VJZA1BHWklRa2dULTM5V1JvcG9SdjBxYjk4MjR3WVppX0tQMEVXSHdya1AxMVhNNGZA0NkJzVWdBLTM2ZAHZAFekJIVGJxdjVIZAF9LMjNFaFVJWVE1eWZADZAWcZD',
            template: '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /><p>{{caption}}</p></a>',
            limit: 8,
        });

        setTimeout(() => {
            feed.run();
        }, 1000);
    });
</script>

{#if $isLoading}
    <Loader/>
{:else}
<div class="homepage">
    <div class="video-holder">
        <video autoplay class="home-video" loop muted playsinline poster="/videos/vid.jpeg" preload="auto"
               tabindex="-1">
            <source src="/videos/vid.mp4" type="video/mp4">
        </video>
        <div class="overlay"></div>
    </div>
</div>
<div class="top">
    <img alt={game_name} class="logo" src="images/logo.png" title={game_name}/>
    <div class="store-badges">
        <a href="https://m.onelink.me/38c819c8">
            <img alt="Download on Google Play" src="/images/apple_badge.svg">
        </a>
        <a href="https://m.onelink.me/601d4a5d">
            <img alt="Download on Apple App Store" src="/images/google_badge.svg">
        </a>
    </div>
    <div class="nav-menu">
        <Button
                class="shop-button orange"
                on:click={() => {
                    logMetric({button: 'shop_button_homepage'});
                    window.location.href = '/shop';
                }}
                primary
        >
            <Fa fw icon={faGift} primaryColor="red" secondaryColor="white" secondaryOpacity={0.8}/>
            {#if $isRedirected}
                {$_('ClaimRewardsButton')}
            {:else}
                {$_('Login')}
            {/if}
        </Button>
    </div>
    <div class="content">
        <Details class="announcement" open={whats_new_modal_open || null}>
            <div class="announcement_banner has_icon animate__animated animate__shakeY animate__delay-2s" id="whats-new-details" slot="summary">
                {@html $_('WhatsNewBannerContent')}
                <h4 class="animate__animated animate__rubberBand animate__delay-3s">
                    <Fa fw icon={faMegaphone} primaryColor="white" secondaryColor="#578ff8" secondaryOpacity={0.8} size="3x"/>
                </h4>
                {#if whats_new_modal_open}
                    <Button
                            class="blue"
                            on:click={() => {
                                whats_new_modal_open = false
                            }}
                            primary
                    >
                        {$_('Close details ↑')}
                    </Button>
                {:else}
                    <Button
                            class="blue"
                            on:click={() => {
                                logMetric({button: 'whats_new_button_homepage'});
                                whats_new_modal_open = true;
                                setTimeout(() => {
                                    scrollIntoView('#whats-new-details');
                                }, 100);
                            }}
                            primary
                    >
                        {$_('View details ↓')}
                    </Button>
                {/if}

            </div>
            <div class="details">
                <div>
                    {@html $_('WhatsNewDetails')}
                    <Splide aria-label="Gift Friend gallery" options={{
                        rewind: true,
                        gap   : '1rem',
                        width: '100%',
                        perPage: 3,
                        perMove: 2,
                        arrows: false,
                        lazyLoad: true,
                        interval: 5000,
                        autoplay: true,
                        speed: 2000
                      }}>
                        <SplideSlide>
                            <Lightbox>
                                <picture>
                                    <source srcset="/images/whats_new_carousel/1.webp" type="image/webp"/>
                                    <source srcset="/images/whats_new_carousel/1.jpg" type="image/jpeg"/>
                                    <img alt="Gift Friend 1" src="/images/whats_new_carousel/1.png"/>
                                </picture>
                            </Lightbox>
                        </SplideSlide>
                        <SplideSlide>
                            <Lightbox>
                                <picture>
                                    <source srcset="/images/whats_new_carousel/2.webp" type="image/webp"/>
                                    <source srcset="/images/whats_new_carousel/2.jpg" type="image/jpeg"/>
                                    <img alt="Gift Friend 2" src="/images/whats_new_carousel/2.png"/>
                                </picture>
                            </Lightbox>
                        </SplideSlide>
                        <SplideSlide>
                            <Lightbox>
                                <picture>
                                    <source srcset="/images/whats_new_carousel/3.webp" type="image/webp"/>
                                    <source srcset="/images/whats_new_carousel/3.jpg" type="image/jpeg"/>
                                    <img alt="Gift Friend 3" src="/images/whats_new_carousel/3.png"/>
                                </picture>
                            </Lightbox>
                        </SplideSlide>
                    </Splide>
                    <Button
                            class="claim-button green"
                            on:click={() => {
                        logMetric({button: 'promo_button_whats_new'});
                        localStorage.setItem('storeSection', 'giftFriend');
                        window.location.href = '/shop';
                    }}
                            primary
                    >
                        {$_('WhatsNewPromoButton')}
                    </Button>
                </div>
            </div>
        </Details>
        <div class="banner animate__animated animate__shakeY animate__delay-2s">
            <h3> {$_('WebshopHomepageBannerHeader')}</h3>
            <span>{$_('WebshopHomepageBannerTimePeriod')}</span>
            <h5>{$_('WebshopHomepageBannerDescription')}</h5>
            <h4 class="animate__animated animate__rubberBand animate__delay-3s">
                <Fa fw icon={faGift} primaryColor="red" secondaryColor="white" secondaryOpacity={0.8} size="3x"/>
            </h4>
            <Button
                    class="green"
                    on:click={() => {
                        logMetric({button: 'promo_button_homepage'});
                        window.location.href = '/shop';
                    }}
                    primary
            >
                {$_('Claim Gift')}
            </Button>
        </div>
        <div class="instagram-section">
            <Fa fw icon={faInstagram} primaryColor="#ffb900"  size="3x"/>
            <h4>{$_('War & Peace')}<span>@warandpeacecivilwar</span></h4>
            <a href="https://www.instagram.com/warandpeacecivilwar/" target="_blank">{$_("Follow")}</a>
        </div>
        <div id="instafeed"></div>
        <h2>Test your history knowledge in the newest strategy MMO with a realistic American Civil War theme! TRAIN
            massive armies of Civil War cannons, go to war with thousands of REAL players, form powerful alliances
            and
            develop your own military strategies.</h2>
        <Splide aria-label="War and Peace gallery" options={{
            rewind: true,
            gap   : '1rem',
            width: '90%',
            perPage: 3,
            perMove: 2,
            arrows: false,
            lazyLoad: true,
            interval: 5000,
            autoplay: true,
            speed: 2000
          }}>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/1.webp" type="image/webp"/>
                        <source srcset="/images/carousel/1.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/1.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/2.webp" type="image/webp"/>
                        <source srcset="/images/carousel/2.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/2.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/3.webp" type="image/webp"/>
                        <source srcset="/images/carousel/3.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/3.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/4.webp" type="image/webp"/>
                        <source srcset="/images/carousel/4.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/4.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/5.webp" type="image/webp"/>
                        <source srcset="/images/carousel/5.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/5.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/6.webp" type="image/webp"/>
                        <source srcset="/images/carousel/6.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/6.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/7.webp" type="image/webp"/>
                        <source srcset="/images/carousel/7.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/7.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/8.webp" type="image/webp"/>
                        <source srcset="/images/carousel/8.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/8.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/9.webp" type="image/webp"/>
                        <source srcset="/images/carousel/9.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/9.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
            <SplideSlide>
                <Lightbox>
                    <picture>
                        <source srcset="/images/carousel/10.webp" type="image/webp"/>
                        <source srcset="/images/carousel/10.jpg" type="image/jpeg"/>
                        <img alt="War and Peace" src="/images/carousel/10.jpg"/>
                    </picture>
                </Lightbox>
            </SplideSlide>
        </Splide>
        <h4>Civil War Game</h4>
        <ul>
            <li>Fight in the America Civil War - side with the Union and Abraham Lincoln or join the Confederacy
                with
                Jefferson Davis.
            </li>
            <li>Real time strategy in epic war battles let you decide the course of history! A revolution on your
                phone!
            </li>
            <li>Multiplayer strategy and tactics will decide the outcome of the war.</li>
            <li>Civil War battle maps based on historical battles.</li>
        </ul>
        <h4>War Strategy Game</h4>
        <ul>
            <li>Use Civil War military weapons and artillery from 1861.</li>
            <li>Build and defend in the most epic fights in American history with one of the most realistic MMO
                strategy
                games.
            </li>
            <li>Command and conquer an army of 1861 soldiers and become a military hero!</li>
            <li>Use base building to create and defend your base of operations.</li>
        </ul>
        <h4>Online Strategy Games</h4>
        <ul>
            <li>Use your American history knowledge to fight on the battlefield.</li>
            <li>PvP battles have your army face off against other players!</li>
            <li>War and Peace leads you to a time where wars cannot be won with helicopters - remember that you’re
                in
                1861 and there are no modern weapons!
            </li>
            <li>Base building, real time strategy, and revolutionary tactics will help you win the Civil War!</li>
        </ul>
        <h4>Army Strategy Games</h4>
        <ul>
            <li>Build a military base and command your army against the enemy.</li>
            <li>Manage soldiers and develop tactics to win this epic war.</li>
            <li>Use revolutionary thinking and military tactics to outwit your foes.</li>
            <li>Step into USA history and be part of the country’s defining war!</li>
            <li>Conquer civil war battle maps without using a tank or battleship!</li>
        </ul>
        <h2>Start base building, develop war tactics, take command and prepare your artillery for the MMO strategy
            war
            in War and Peace!</h2>
    </div>
</div>
{/if}

<style lang="scss">
  @import 'src/styles/vars.scss';

  :global {
    #instafeed {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      //grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 0.7rem;
      grid-row-gap: 0.7rem;
      max-width: 100%;
      margin: auto;
      place-items: center;
      justify-content: center;
      align-items: start;
      justify-items: center;


      a{
        display: block;
        width: 100%;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        border: 5px solid rgba(0, 0, 0, 0.5);

        &:hover {
            img {
                transform: scale(1.5) rotate(25deg);
            }

            p {
                transform: translateY(0);
                opacity: 1;
                height: auto;
                max-height: 100px;
            }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        transition: transform 0.5s ease;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax($store-pack-width, 0.9fr));
      }

      p {
        font-size: 0.8em;
        line-height: 1.2em;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
        text-align: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 0.5em;
        height: 0;
        max-height: 0px;
        overflow-y: auto;
        opacity: 0;
        transition: transform 0.5s ease, opacity 0.5s ease, height 0.5s ease;
      }
    }

    .instagram-section {
      display: flex;
      position: relative;
      height: 90px;
      width: 90%;
      overflow: hidden;
      align-items: center;
      border-bottom: 1px dotted var(--color-primary);
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 20px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
        margin-top: 10px;
        h4 {
          font-size: 2rem !important;

          span {
            font-size: 1.3rem !important;
            line-height: 0.4rem !important;
          }
        }

        a {
          right: 0 !important;
        }
      }

      h4 {
        font-size: 2.5rem;
        text-align: center;
        color: #ffb900;
        font-weight: bold;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 1px 1px 3px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          font-size: 1.7rem;
          font-weight: normal;
          color: white;
          line-height: 0.5rem;
        }
      }

      a {
        position: absolute;
        right: 30px;
        padding: 7px 20px;
        border: 3px solid var(--color-primary);
        border-radius: 10px;
        text-decoration: none;
        font-weight: bold;

      }
    }

    .splide {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      .splide__pagination {
        bottom: -2em;
      }

      .splide__slide {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
          border: 5px solid rgba(0, 0, 0, 0.5);
        }
      }
    }

    details summary::-webkit-details-marker,
    details summary::marker {
      display: none;
      content: "";
    }

    details.announcement {
      width: 90%;
      margin-top: 50px;

      summary {
        list-style: none;
        width: 100%;
        display: flex;
        box-sizing: border-box;
        position: relative;
        z-index: 2;

      }

      .claim-button {
        margin-top: 20px;
        width: 95%;
      }
    }

    .nav-menu {
      background: #ff7700;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #ff7700 50%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 5px;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 15px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

      .shop-button {
        width: 200px;
        position: relative;
        top: -38px;
        border-radius: 35px !important;
        height: 60px;
        font-size: 2rem !important;
      }
    }

    .banner, .announcement_banner {
      .button {
        position: absolute;
        width: 200px;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        margin: 0;

        @media (max-width: 700px) {
          width: auto;
          top: auto;
          left: 0;
          right: 0;
          bottom: 0;
          transform: none;
        }
      }
    }

    .announcement_banner {
      --banner-accent: rgba(219, 224, 98, 0.5);
      --banner-main: #252936;
      --banner-main-darker: #4a35a9;
      --banner-dark: #578ff8;
      min-height: 90px;
      width: 100%;
      background: var(--banner-main);
      background: linear-gradient(145deg, var(--banner-main) 0%, var(--banner-main-darker) 100%);
      border-radius: 10px;
      position: relative;
      border: 5px solid var(--banner-dark);
      box-shadow: 0 0 10px var(--banner-dark), 0 0 30px rgba(0, 0, 0, 0.2) inset;
      cursor: default;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 700px) {
        padding: 7px 20px 55px;
      }

      &.has_icon {
        padding-left: 90px;

        @media (max-width: 700px) {
          justify-content: flex-start;
          min-height: 140px;
          height: auto;
          padding: 7px 45px 50px 20px;

          h3 {
            white-space: initial;
          }

          h4 {
            height: 64px !important;
            width: 64px !important;
            font-size: 1.2rem;
            left: auto !important;
            right: -20px;
          }
        }

        h4 {
          background: #252936;
          background: linear-gradient(146deg, #252936 0%, #4a35a9 100%);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 110px;
          width: 110px;
          position: absolute;
          text-align: center;
          margin: 0;
          font-weight: 700;
          font-family: var(--font-family-sans-condensed);
          line-height: 22px;
          letter-spacing: -0.5px;
          overflow: hidden;
          top: -14px;
          left: -30px;
          //outline: 5px solid var(--banner-dark);
          box-shadow: 0 4px 0 #301806, 0 0 20px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.2) inset;
          transform: rotate(-15deg);

          span {
            font-size: 4rem;
            color: #4a2408;
            text-shadow: 0 2px 0 rgb(255 255 255 / 30%);
          }
        }
      }

      h3 {
        font-family: var(--font-family-sans-condensed);
        font-weight: bold;
        font-size: 2.3rem;
        font-variant: small-caps;
        text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.1);
        line-height: 2.6rem;
        color: aliceblue;
        margin: 0;
        white-space: nowrap;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.7rem;
      }

      h3, p {
        max-width: 60%;

        @media (max-width: 700px) {
          max-width: 90%;
        }
      }
    }
  }

  .details {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto;
    background-image: radial-gradient(50% 75%, #fff4d7 0%, #f5d5a0 76%) !important;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%), 0 2px 4px 0 rgb(0 0 0 / 50%), 0 2px 5px 0 rgb(0 0 0 / 14%), inset 0 -2px 0 0 rgb(0 0 0 / 43%), inset 0 2px 0 0 #ffffff !important;
    border-radius: 4px;
    color: $bg-color;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);

    & > div {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    table {
      color: $color-dark-text;
      margin-top: 15px;

      th {
        background-color: $light-color;
        padding: 5px;
        line-height: 1.2;
      }

      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      td {
        padding: 5px;

        &:first-child {
          font-weight: 600;
        }

        &:last-child {
          font-weight: 600;
          color: #1e561e;
        }
      }
    }

    a {
      color: $bg-color;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.5;
      text-align: justify;
    }
  }

  .banner {
    --banner-accent: rgba(219, 224, 98, 0.5);
    --banner-main: #be2e2e;
    --banner-main-darker: #fa7522;
    --banner-dark: #ffbe44;
    height: 90px;
    width: 90%;
    background: var(--banner-main);
    background: linear-gradient(145deg, var(--banner-main) 0%, var(--banner-main-darker) 100%);
    border-radius: 10px;
    position: relative;
    border: 5px solid var(--banner-dark);
    box-shadow: 0 0 10px var(--banner-dark), 0 0 30px rgba(0, 0, 0, 0.2) inset;
    cursor: default;
    margin: 50px auto 0;
    padding-left: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      color: yellow;
    }

    @media (max-width: 700px) {
      justify-content: flex-start;
      min-height: 140px;
      height: auto;
      padding: 7px 20px;
      padding-bottom: 50px;

      h4 {
        height: 64px !important;
        width: 64px !important;
        font-size: 1.2rem;
        left: auto !important;
        right: -20px;
        padding-top: 12px !important;
      }
    }

    h3 {
      font-family: var(--font-family-sans-condensed);
      font-weight: bold;
      font-size: 2.8rem;
      font-variant: small-caps;
      text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.1);
      line-height: 2.6rem;
      color: aliceblue;
      margin: 0;
      white-space: nowrap;

      span {
        color: yellow;
      }
    }

    h5 {
      font-family: var(--font-family-sans-condensed);
      font-size: 1.4rem;
      font-weight: 600;
      text-shadow: 4px 3px 0 rgba(0, 0, 0, 0.1);
      color: aliceblue;
      line-height: 2rem;
      text-transform: uppercase;
      margin: 0;
    }

    h4 {
      background: rgb(251, 244, 63);
      background: linear-gradient(146deg, rgba(251, 244, 63, 1) 0%, rgba(252, 117, 70, 1) 100%);
      border-radius: 50%;
      display: inline-block;
      height: 110px;
      width: 110px;
      position: absolute;
      text-align: center;
      margin: 0;
      font-weight: 700;
      font-family: var(--font-family-sans-condensed);
      line-height: 22px;
      padding-top: 22px;
      letter-spacing: -0.5px;
      overflow: hidden;
      top: -14px;
      left: -30px;
      //outline: 5px solid var(--banner-dark);
      box-shadow: 0 4px 0 #301806, 0 0 20px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.2) inset;
      transform: rotate(-15deg);

      span {
        font-size: 4rem;
        color: #4a2408;
        text-shadow: 0 2px 0 rgb(255 255 255 / 30%);
      }
    }

  }

  .homepage {
    position: absolute;
    width: 100%;
  }

  .content {
    max-width: 1000px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: calc(12px + 0.390625vw);
      text-align: center;
      margin-top: 50px;
      color: #ffe9ad;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 1px 1px 3px rgba(0, 0, 0, 0.5);
      padding: 0 20px;
    }

    & > h4 {
      font-size: 2.5rem;
      text-align: center;
      margin-top: 50px;
      color: #ffb900;
      font-weight: bold;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 1px 1px 3px rgba(0, 0, 0, 0.5);
      //font-family: var(--font-family-sans-condensed);
    }

    ul {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: 10px;
      list-style: none;
      width: 100%;

      li {
        font-size: 1.5rem;
        text-align: center;
      }
    }
  }


  .top {
    position: relative;
    top: 0;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .store-badges {
      display: flex;
      margin-top: -70px;
      position: relative;
      z-index: 20;

      @media (max-width: 700px) {
        margin-top: -100px;
        //flex-direction: column;
        //align-items: center;
      }

      a {
        padding: 0 10px;
      }

      img {
        height: 60px;
        width: auto;
      }
    }

    .logo {
      max-width: 500px;
      margin-top: -30px;
      min-height: 427px;

      @media (max-width: 700px) {
        transform: scale(0.7);
        margin-top: -65px;
      }

    }
  }

  .video-holder {
    position: relative;
    height: 100%;
    z-index: -11;

    .home-video {
      width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to right, var(--bg-color) 0, rgba(0, 0, 0, 0) 56%),
      linear-gradient(to top, var(--bg-color) 0, rgba(0, 0, 0, 0) 56%),
      linear-gradient(to left, var(--bg-color) 0, rgba(0, 0, 0, 0) 56%);
    }
  }
</style>