<script>
  import { onMount, onDestroy } from 'svelte';

  export let endDate;
  let timer = null;
  let remaining;
  let difference;
  let inactive = true;

  function countdownTimer() {
    difference = +new Date(endDate) - +new Date();
    remaining = '';

    inactive = true;

    if (difference > 0) {
      inactive = false;
      const parts = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
          .toString()
          .padStart(2, '0'),
        minutes: Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, '0'),
        seconds: Math.floor((difference / 1000) % 60)
          .toString()
          .padStart(2, '0'),
      };

      remaining = `${parts.minutes}:${parts.seconds}`;

      // more than 1 hour
      if (difference / 1000 >= 60 * 60) {
        remaining = `${parts.hours}:${parts.minutes}:${parts.seconds}`;
      }

      // more than 1 day
      if (difference / 1000 >= 60 * 60 * 24) {
        remaining = `${parts.days}d ${parts.hours}:${parts.minutes}:${parts.seconds}`;
      }
    }
  }

  onMount(() => {
    timer = setInterval(countdownTimer, 1000);
  });

  onDestroy(() => {
    if (timer) {
      clearInterval(timer);
    }
  });

  $: {
    if (difference === 0) {
      clearInterval(timer);
      timer = null;
    }
  }
</script>

<span class:inactive class="timer"><slot /> {remaining}</span>

<style lang="scss">
  .inactive {
    opacity: 0;
  }
</style>
