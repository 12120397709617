<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title =
            gameItem.type === 'GOLD'
                ? $_('LootCrateKeyGold')
                : $_('LootCrateKeySilver');
        itemDetails.description =
            gameItem.type === 'GOLD'
                ? $_('LootCrateKeyGoldDescription')
                : $_('LootCrateKeySilverDescription');

        itemDetails.amount = item.amount;
        itemDetails.icon = gameItem.type === 'GOLD' ? 'key-gold' : 'key-silver';
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
