<script>
    import {_} from 'svelte-i18n';
    import {afterUpdate, onMount} from "svelte";
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faXmark} from '@fortawesome/free-solid-svg-icons';
    import {getAnalytics, logEvent} from 'firebase/analytics';
    import {logMetric} from "../../metrics";
    import {Button, Card} from 'svelte-chota';

    export let gift_friend_modal_hide;

    const analytics = getAnalytics();

    function addEventListener() {
        const background = document.querySelector('.background');
        if (background) {
            background.addEventListener('click', gift_friend_modal_hide);
        }
    }

    onMount(async () => {
        await logMetric({show_gift_friend_modal: "true"});

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'gift_friend_modal',
            firebase_screen_class: 'web',
        });

        addEventListener();
    });

    afterUpdate(addEventListener);
</script>

<Card class="gift-friend-modal">
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a class="close-button" href="javascript:" on:click={gift_friend_modal_hide}>
        <Fa fw icon={faXmark} primaryColor="#240c05" size="2x"/>
    </a>
    <h1>{$_('GiftFriendModalTitle')}</h1>
    <hr>
    <h4>{@html $_('GiftFriendModalRequirementsInfo').replace(/\. /g, ".<br/>")}</h4>
    <ul>
        {@html $_('GiftFriendModalRequirements')}
    </ul>
    <p class="keep-playing">{$_('GiftFriendModalBottomText')}</p>
    <Button
            class="go-back orange"
            primary
            on:click={gift_friend_modal_hide}
    >
        {$_('GiftFriendModalButton')}
    </Button>
</Card>

<style lang="scss">
  @import '../../styles/vars.scss';

  :global {
    .gift-friend-modal-wrapper {
      &.modal {
        min-width: 300px !important;
      }

      @media (max-width: 319px) {
        &.modal {
          min-width: 240px !important;
        }
      }
    }

    .gift-friend-modal {
      &.card {
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: left;
        overflow: hidden;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
        transition: all 0.2s ease-in;
        position: relative;

        li {
          list-style: none;
          margin: 0;
          padding: 0 0 0 5px;
          font-size: 0.9em;
          color: $dark-color;

          label {
            display: flex;
            align-items: center;
          }
        }


        h1 {
          font-weight: 700;
          font-family: var(--font-family-sans-condensed);
          padding: 1rem 2rem;
          line-height: 1.2em;
          margin-bottom: 0;
          margin-top: 0;
          text-align: center;
          color: rgb(58, 20, 7);
          text-shadow: 0.5px 0.866px 0 rgba(255, 255, 255, 0.4);
        }

        h4 {
          font-family: var(--font-family-sans-condensed);
          max-width: 400px;
          font-size: clamp(1.6rem, 5vw, 18px);
          font-weight: bold;
          color: rgb(58, 20, 7);
          text-shadow: 0.5px 0.866px 0 rgba(255, 255, 255, 0.4);
          margin: 20px 0;
        }

        hr {
          width: 100%;
          border: 0;
          height: 2px;
          background: rgb(58, 20, 7);
          margin: 0;
          position: absolute;
          left: 0;
        }

        ul {
          margin-bottom: 20px;
        }

        .keep-playing {
          margin-bottom: 20%;
        }

        .close-button {
          position: absolute;
          top: 0;
          right: 0;
          padding: 7px 2px;
        }
      }

    }
  }
</style>