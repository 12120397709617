<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    const summonFortMapDetails = () => {
        let summonFortMapDetails = {};

        switch (gameItem.type) {
            case 3:
                summonFortMapDetails.title =
                    'FortMapHalloween' + gameItem.level + 'Title';
                summonFortMapDetails.description =
                    'FortMapHalloween' + gameItem.level + 'Description';
                summonFortMapDetails.icon =
                    'fortsAssaultHalloween' + gameItem.level + 'Item';
                break;
            case 20:
                summonFortMapDetails.title =
                    'FortMapGeneric' + gameItem.level + 'Title';
                summonFortMapDetails.description =
                    'FortMapGeneric' + gameItem.level + 'Description';
                summonFortMapDetails.icon =
                    'fortsAssaultGeneric' + gameItem.level + 'Item';
                break;
        }

        return summonFortMapDetails;
    };

    function setItemDetails() {
        itemDetails.title = $_(summonFortMapDetails().title);
        itemDetails.description = $_(summonFortMapDetails().description);
        itemDetails.amount = item.amount;
        itemDetails.icon = $_(summonFortMapDetails().icon);
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
