<script>
    import {_, locale, number} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours}`;
        } else if (seconds >= 60) {
            timeString = `${minutes}`;
        }

        return timeString;
    }

    function setItemDetails() {
        itemDetails.title = $_(`CastleSkin${gameItem.type}ItemTitle`, {
            values: {DAYS: formatTimeFriendly(gameItem.seconds)},
        });
        itemDetails.description = $_(`CastleSkin${gameItem.type}Description`);
        itemDetails.amount = item.amount;
        itemDetails.icon = `castleSkin_${gameItem.type}`;
        itemDetails.rarity = `3`;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
