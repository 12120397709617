<script>
    import {Button, Card, Input, Modal} from "svelte-chota";
    import {_} from "svelte-i18n";
    import {user} from "../../stores.js";
    import ItemsListing from "../ItemsListing.svelte";
    import {redeem_coupon_route} from "../../constants.js";
    import {logMetric} from "../../metrics.js";
    export let globals = {};

    let code = '';
    let loading;
    let showRewards = false;
    let showError = false;
    let validCode;
    let userName = $user.user.username;
    let redeemErrorMessage = "";

    let redeemResponse = {
        error: null,
        rewards: null,
    };

    const handleSubmit = () => {
        let redeemData = {
            code,
        };
        
        loading = true;
        redeemResponse = {
            error: null,
            rewards: null,
        };
        fetch(redeem_coupon_route, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(redeemData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.statusCode) {
                    redeemResponse = {
                        ...redeemResponse,
                        error: data.message,
                    };
                    
                    redeemErrorMessage = setErrorMessage(data.message);
                    
                    showError = true;
                } else {
                    redeemResponse = {
                        ...redeemResponse,
                        rewards: data.rewards,
                    };
                    
                    showRewards = true;
                }
            })
            .catch((error) => {
                Rollbar.critical(error);
            })
            .finally(() => {
                logMetric({
                    redeem: {
                        username: userName,
                        ...(redeemResponse.error !== null && {error: redeemResponse.error})
                    },
                });
                loading = false;
            });
    };

    function setErrorMessage(serverErrorMessage) {
        if(serverErrorMessage.includes('Invalid coupon code')) {
            return $_('RedeemCodeErrorInvalidCode');
        }
        
        if(serverErrorMessage.includes('Campaign not started')) {
            return $_('RedeemCodeErrorCampaignStart');
        }
        
        if(serverErrorMessage.includes('Campaign expired')) {
            return $_('RedeemCodeErrorCampaignExpired');
        }
        
        if(serverErrorMessage.includes('No coupons left')) {
            return $_('RedeemCodeErrorLimit');
        }
        
        if(serverErrorMessage.includes('Invalid platform')) {
            return $_('RedeemCodeErrorPlatform');
        }
        
        if(serverErrorMessage.includes('Invalid created at')) {
            return $_('RedeemCodeErrorInvalidCreatedAt');
        }
        
        if(serverErrorMessage.includes('Already redeemed')) {
            return $_('RedeemCodeErrorAlreadyRedeemed');
        }
        
        return $_('PleaseTryAgainLater');
    }
    
    let onHideSuccessModal = () => {
        showRewards = false;
    };
    
    let onHideErrorModal = () => {
        showError = false;
    };

    globals.formatTimeFriendly = formatTimeFriendly;

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }
    
    $: {
        validCode = code.length >= 3 && code.length <= 10;
    }
</script>

<div class="redeem">
    <h1>{$_('RedeemCoupon')}</h1>
    <div class="form">
        <form
                on:submit|preventDefault={handleSubmit}
                autocomplete="on"
                class="flex flex-row"
        >
            <div class="form-inner">
                <p>
                    <Input
                            id="password"
                            name="password"
                            bind:value={code}
                            placeholder={$_('RedeemCodeName')}
                            class="input-field"
                            autocorrect="off"
                            autocapitalize="none"
                            autocomplete="off"
                            type="search"
                            minlength="3"
                            maxlength="10"
                            required
                    />
                </p>
                <Button submit class="blue redeem-button" {loading} disabled={!validCode}>
                    {$_('RedeemCodeRedeem')}</Button>
            </div>
        </form>
        <h3>{$_('RedeemCodeInputDescription')}</h3>
    </div>
    <Modal bind:open={showRewards} close={onHideSuccessModal}>
        <Card class="howto">
            <a href="javascript:" on:click={onHideSuccessModal} class="close-button">
            </a>
            <p>{$_('EventPrizeMessageSubtitle')}</p>
            <ItemsListing globals={globals} goldAmount={0} items={redeemResponse.rewards} storePack={{}} areRewards={false}/>
            <Button submit class="blue" on:click={onHideSuccessModal} {loading}
            >{$_('Ok')}</Button
            >
        </Card>
    </Modal>
    <Modal bind:open={showError} close={onHideErrorModal}>
        <Card class="howto">
            <a href="javascript:" on:click={onHideErrorModal} class="close-button">
            </a>
            <p>{redeemErrorMessage}</p>
            <Button submit class="blue" on:click={onHideErrorModal} {loading}
            >{$_('Ok')}</Button
            >
        </Card>
    </Modal>
</div>

<style lang="scss">
  @import 'src/styles/vars.scss';

  :global {
    .redeem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;

      label {
        text-align: left;
        display: flex;
        align-items: center;
        font-size: 12px;

        input {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      h1, h3 {
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      }

      h1 {
        color: #e2e29b;
      }

      h3 {
        margin-top: 10px;
        padding: 0;
        font-size: 16px;
        color: #fffce9;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
      }

      .form {
        width: 300px;
      }
    }

    .form-inner {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media(min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .redeem-button {
        height: 45px;
        width: auto;
        padding: 0.5em 1em;
        margin-top: 10px;
        margin-left: 0;

        @media(min-width: 768px) {
          margin-top: 0;
          margin-left: 10px;
        }

        &:disabled {
          opacity: 1;
        }
        
        &:hover {
          opacity: 1;
        }
      }
    }
  }
</style>
