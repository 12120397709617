<script>
  import { _ } from 'svelte-i18n';
</script>

<div class="starburst" title={$_('BonusItemsStore')}>
  <p><slot /><small>{$_('Bonus')}</small></p>
  <div class="border" />
</div>

<style lang="scss">
  .starburst {
    --stripe1: #e45922;
    --stripe2: #e14d20;
    background-image: radial-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      conic-gradient(
        var(--stripe1) 0%,
        var(--stripe1) 5%,
        var(--stripe2) 5%,
        var(--stripe2) 10%,
        var(--stripe1) 10%,
        var(--stripe1) 15%,
        var(--stripe2) 15%,
        var(--stripe2) 20%,
        var(--stripe1) 20%,
        var(--stripe1) 25%,
        var(--stripe2) 25%,
        var(--stripe2) 30%,
        var(--stripe1) 30%,
        var(--stripe1) 35%,
        var(--stripe2) 35%,
        var(--stripe2) 40%,
        var(--stripe1) 40%,
        var(--stripe1) 45%,
        var(--stripe2) 45%,
        var(--stripe2) 50%,
        var(--stripe1) 50%,
        var(--stripe1) 55%,
        var(--stripe2) 55%,
        var(--stripe2) 60%,
        var(--stripe1) 60%,
        var(--stripe1) 65%,
        var(--stripe2) 65%,
        var(--stripe2) 70%,
        var(--stripe1) 70%,
        var(--stripe1) 75%,
        var(--stripe2) 75%,
        var(--stripe2) 80%,
        var(--stripe1) 80%,
        var(--stripe1) 85%,
        var(--stripe2) 85%,
        var(--stripe2) 90%,
        var(--stripe1) 90%,
        var(--stripe1) 95%,
        var(--stripe2) 95%,
        var(--stripe2) 100%
      );
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 20;
    top: -15px;
    right: -15px;
    width: 60px;
    aspect-ratio: 1;
    transform: rotate(-25deg);
    transition: 0.15s ease-in;
    cursor: default;
    clip-path: polygon(
      100% 50%,
      94.45% 57.04%,
      97.55% 65.45%,
      90.1% 70.43%,
      90.45% 79.39%,
      81.82% 81.82%,
      79.39% 90.45%,
      70.43% 90.1%,
      65.45% 97.55%,
      57.04% 94.45%,
      50% 100%,
      42.96% 94.45%,
      34.55% 97.55%,
      29.57% 90.1%,
      20.61% 90.45%,
      18.18% 81.82%,
      9.55% 79.39%,
      9.9% 70.43%,
      2.45% 65.45%,
      5.55% 57.04%,
      0% 50%,
      5.55% 42.96%,
      2.45% 34.55%,
      9.9% 29.57%,
      9.55% 20.61%,
      18.18% 18.18%,
      20.61% 9.55%,
      29.57% 9.9%,
      34.55% 2.45%,
      42.96% 5.55%,
      50% 0%,
      57.04% 5.55%,
      65.45% 2.45%,
      70.43% 9.9%,
      79.39% 9.55%,
      81.82% 18.18%,
      90.45% 20.61%,
      90.1% 29.57%,
      97.55% 34.55%,
      94.45% 42.96%
    );
    box-shadow: inset 0 0 5px 2px rgba(0, 0, 0, 0.5);

    &:hover {
      transform: scale(120%);
    }

    p {
      position: absolute;
      display: flex;
      width: 100%;
      padding: 0 8px;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      font-weight: 700;
      color: yellow;
      font-size: 0.9em;
      line-height: 1em;
      flex-direction: column;

      small {
        text-transform: uppercase;
        color: white;
        font-size: 0.8em;
        line-height: 0.8em;
      }
    }

    .border {
      background-color: yellow;
      width: 100%;
      aspect-ratio: 1;
      clip-path: polygon(
        100% 50%,
        calc(99.38% - 4.94%) calc(57.82% - 0.78%),
        97.55% 65.45%,
        calc(94.55% - 4.46%) calc(72.7% - 2.27%),
        90.45% 79.39%,
        calc(85.36% - 3.54%) calc(85.36% - 3.54%),
        79.39% 90.45%,
        calc(72.7% - 2.27%) calc(94.55% - 4.46%),
        65.45% 97.55%,
        calc(57.82% - 0.78%) calc(99.38% - 4.94%),
        50% 100%,
        calc(42.18% - -0.78%) calc(99.38% - 4.94%),
        34.55% 97.55%,
        calc(27.3% - -2.27%) calc(94.55% - 4.46%),
        20.61% 90.45%,
        calc(14.64% - -3.54%) calc(85.36% - 3.54%),
        9.55% 79.39%,
        calc(5.45% - -4.46%) calc(72.7% - 2.27%),
        2.45% 65.45%,
        calc(0.62% - -4.94%) calc(57.82% - 0.78%),
        0% 50%,
        calc(0.62% - -4.94%) calc(42.18% - -0.78%),
        2.45% 34.55%,
        calc(5.45% - -4.46%) calc(27.3% - -2.27%),
        9.55% 20.61%,
        calc(14.64% - -3.54%) calc(14.64% - -3.54%),
        20.61% 9.55%,
        calc(27.3% - -2.27%) calc(5.45% - -4.46%),
        34.55% 2.45%,
        calc(42.18% - -0.78%) calc(0.62% - -4.94%),
        50% 0%,
        calc(57.82% - 0.78%) calc(0.62% - -4.94%),
        65.45% 2.45%,
        calc(72.7% - 2.27%) calc(5.45% - -4.46%),
        79.39% 9.55%,
        calc(85.36% - 3.54%) calc(14.64% - -3.54%),
        90.45% 20.61%,
        calc(94.55% - 4.46%) calc(27.3% - -2.27%),
        97.55% 34.55%,
        calc(99.38% - 4.94%) calc(42.18% - -0.78%),
        100% 50%,
        calc(100% - 3px) 50%,
        calc(94.45% - 2.67px) calc(42.96% - -0.42px),
        calc(97.55% - 2.85px) calc(34.55% - -0.93px),
        calc(90.1% - 2.41px) calc(29.57% - -1.23px),
        calc(90.45% - 2.43px) calc(20.61% - -1.76px),
        calc(81.82% - 1.91px) calc(18.18% - -1.91px),
        calc(79.39% - 1.76px) calc(9.55% - -2.43px),
        calc(70.43% - 1.23px) calc(9.9% - -2.41px),
        calc(65.45% - 0.93px) calc(2.45% - -2.85px),
        calc(57.04% - 0.42px) calc(5.55% - -2.67px),
        calc(50% - -0px) calc(0% - -3px),
        calc(42.96% - -0.42px) calc(5.55% - -2.67px),
        calc(34.55% - -0.93px) calc(2.45% - -2.85px),
        calc(29.57% - -1.23px) calc(9.9% - -2.41px),
        calc(20.61% - -1.76px) calc(9.55% - -2.43px),
        calc(18.18% - -1.91px) calc(18.18% - -1.91px),
        calc(9.55% - -2.43px) calc(20.61% - -1.76px),
        calc(9.9% - -2.41px) calc(29.57% - -1.23px),
        calc(2.45% - -2.85px) calc(34.55% - -0.93px),
        calc(5.55% - -2.67px) calc(42.96% - -0.42px),
        calc(0% - -3px) calc(50% - 0px),
        calc(5.55% - -2.67px) calc(57.04% - 0.42px),
        calc(2.45% - -2.85px) calc(65.45% - 0.93px),
        calc(9.9% - -2.41px) calc(70.43% - 1.23px),
        calc(9.55% - -2.43px) calc(79.39% - 1.76px),
        calc(18.18% - -1.91px) calc(81.82% - 1.91px),
        calc(20.61% - -1.76px) calc(90.45% - 2.43px),
        calc(29.57% - -1.23px) calc(90.1% - 2.41px),
        calc(34.55% - -0.93px) calc(97.55% - 2.85px),
        calc(42.96% - -0.42px) calc(94.45% - 2.67px),
        calc(50% - 0px) calc(100% - 3px),
        calc(57.04% - 0.42px) calc(94.45% - 2.67px),
        calc(65.45% - 0.93px) calc(97.55% - 2.85px),
        calc(70.43% - 1.23px) calc(90.1% - 2.41px),
        calc(79.39% - 1.76px) calc(90.45% - 2.43px),
        calc(81.82% - 1.91px) calc(81.82% - 1.91px),
        calc(90.45% - 2.43px) calc(79.39% - 1.76px),
        calc(90.1% - 2.41px) calc(70.43% - 1.23px),
        calc(97.55% - 2.85px) calc(65.45% - 0.93px),
        calc(94.45% - 2.67px) calc(57.04% - 0.42px),
        calc(100% - 3px) calc(50% - 0px)
      );
    }
  }
</style>
