import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, deleteToken } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let messaging;

async function initializeMessaging() {
    if (await isSupported()) {
        messaging = getMessaging(app);
    } else {
        console.warn('This browser does not support the APIs required for Firebase Messaging.');
    }
}

initializeMessaging();

export { messaging, analytics, isSupported, deleteToken, logEvent };