<script>
    import {_, locale, number} from 'svelte-i18n';
    import {gameConfig} from "../../stores.js";
    import Item from './Item.svelte';

    export let item;
    export let bonus;
    export let goldAmount;
    export let itemsAmount;
    export let itemDetails = {};

    if (goldAmount === 0) {
        goldAmount = $gameConfig.gameItems[item.id].goldValue;
    }

    function getRarity() {
        let itemRarity = 1;

        if (goldAmount > 100 && goldAmount < 2000) {
            itemRarity = 2;
        } else if (goldAmount >= 1200 && goldAmount <= 7500) {
            itemRarity = 3;
        } else if (goldAmount >= 7500) {
            itemRarity = 4;
        }

        return itemRarity;
    }

    function setItemDetails() {
        itemDetails.title = bonus ? $number(goldAmount) + ' ' + $_('Gold') + ' (' + $_('Bonus') + ')' : $number(goldAmount) + ' ' + $_('Gold');
        itemDetails.description =
            bonus ? $_('PacksReceiveGold') + ' ' + $number(goldAmount) + ' ' + $_('Bonus') + ' ' + $_('Gold') : $_('PacksReceiveGold') + ' ' + $number(goldAmount) + ' ' + $_('Gold');
        itemDetails.amount = itemsAmount;
        itemDetails.icon = 'gold_l';
        itemDetails.rarity = getRarity();
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
