<script>
    import {_} from "svelte-i18n";
    import PackListing from "../PackListing.svelte";
    import {Modal} from "svelte-chota";
    import LuckyDrawModal from "../modals/LuckyDrawModal.svelte";
    import {logMetric} from "../../metrics.js";
    import {luckyDrawEvent} from "../../stores.js";

    function redirectToDailyDeals() {
        // Select all the span elements within the element with class "tabs"
        let spans = document.querySelectorAll('.tabs span');

        // Loop through the span elements
        for (let i = 0; i < spans.length; i++) {
            let span = spans[i];

            // Example based on text content:
            if (span.textContent.includes($_('NewShopTabDailyDeals'))) {
                // Simulate a click on the span
                span.click();
                break;
            }
        }
    }

    let show_lucky_draw_packs = false;
    const lucky_draw_packs_show = () => show_lucky_draw_packs = true;

    let lucky_draw_modal_open = false;
    const lucky_draw_modal_show = () => lucky_draw_modal_open = true;
    export let lucky_draw_modal_hide = (x, dont_redirect) => {
        if (typeof dont_redirect === 'undefined') {
            redirectToDailyDeals();
        }
        lucky_draw_modal_open = false;
        logMetric({button: 'lucky-draw-close-modal'});
    };

    if (localStorage.getItem("luckyDrawSeen") === null) {
        localStorage.setItem("luckyDrawSeen", "true");
    }

    if (!$luckyDrawEvent.availableDiscount) {
        lucky_draw_modal_show();
    } else {
        lucky_draw_packs_show();
    }
</script>

<div class="luckyDraw">
    {#if show_lucky_draw_packs}
        <section class="pack-listing">
            <PackListing
                    storeSection="luckyDraw"
                    title={$_('LuckyDraw')}
            />
        </section>
    {/if}
</div>

<Modal class="lucky-draw-modal-wrapper" bind:open={lucky_draw_modal_open}>
    <LuckyDrawModal {lucky_draw_modal_hide} {lucky_draw_packs_show}/>
</Modal>

<style lang="scss">
  @import 'src/styles/vars.scss';

  :global {
    .luckyDraw {
      margin-top: -20px;
    }
  }
</style>
