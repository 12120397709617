import {getLocaleFromNavigator, init, locale, register} from 'svelte-i18n';
import {amazon_language_files_url} from './constants';

export const fallbackLocale = 'en';
export const languages = [fallbackLocale, 'es', 'de', 'fr', 'it', 'ja', 'pt', 'ru', 'tr', 'zh'];

async function fetchLanguageFile(languageCode) {
    const response = await fetch(
        `${amazon_language_files_url + languageCode}.json.gz?__TIMESTAMP__`,
        {
            method: 'GET',
        },
    );

    if (!response.ok) {
        Rollbar.error(`Fetch language file error: ${response.status}`)
    }
    
    return await response.json();
}

locale.subscribe((language) => {
    if (language) localStorage.setItem('locale', language);
});

register('en', () => fetchLanguageFile('English'));
register('es', () => fetchLanguageFile('Spanish'));
register('de', () => fetchLanguageFile('German'));
register('fr', () => fetchLanguageFile('French'));
register('it', () => fetchLanguageFile('Italian'));
register('ja', () => fetchLanguageFile('Japanese'));
register('pt', () => fetchLanguageFile('Portuguese'));
register('ru', () => fetchLanguageFile('Russian'));
register('tr', () => fetchLanguageFile('Turkish'));
register('zh', () => fetchLanguageFile('Chinese'));

let initialLocale;
const detectedLocale =
    localStorage.getItem('locale') || getLocaleFromNavigator();
if (languages.indexOf(detectedLocale) > -1) initialLocale = detectedLocale;
if (!initialLocale && detectedLocale.indexOf('-') > 0) {
    const foundLng = languages.find((l) => detectedLocale.indexOf(l + '-') === 0);
    if (foundLng) initialLocale = foundLng;
}
if (!initialLocale) initialLocale = fallbackLocale;

init({
    fallbackLocale: fallbackLocale,
    initialLocale: initialLocale,
    formats: {
        number: {
            USD: { style: 'currency', currency: 'USD' },
        },
    },
});
