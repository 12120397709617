<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_(`VanityFrame${gameItem.frameId}Title`);
        itemDetails.description = $_(`VanityFrame${gameItem.frameId}Description`);
        itemDetails.amount = item.amount;
        itemDetails.icon = `vanity_frame_${gameItem.frameId}`;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }


    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
