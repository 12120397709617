<script>
    import {storePacks, subscriptions, rechargeRewardsEvent} from '../stores';
    import {getContext} from 'svelte';
    import Loader from '../lib/Loader.svelte';
    import StorePack from './StorePack.svelte';
    import FreePack from './FreePack.svelte';
    import SubscriptionPack from './SubscriptionPack.svelte';
    import GoldRushPack from "./GoldRushPack.svelte";
    import {vipLevel} from '../stores';

    export let tabIndex = 0;
    export let storeSection = '';
    export let title = '';

    const {buyPack} = getContext('buyPack');
    const freeWebshopPacks = ['wssubdays7pack1', 'wssubdays7pack5', 'wssubdays14pack10', 'stpatrickwebshop20', 'stpatrickwebshop300'];

    function subscriptionCondition(subscription) {
        // Purchased subscriptions are of 2 types: 
        // - normal (that gives items)  
        // - resources (that gives a single resource)

        // The resources subscriptions are missing the rewards.items property in the object, so we can use that to differentiate between the two types

        if (storeSection === 'subscriptions') {
            // This check is to ignore purchased resources from the subscriptions tab
            return subscription.rewards.items !== undefined && subscription.rewards.items.length > 1;
        } else {
            // This check is to ignore purchased subscriptions from the resources tab
            return subscription.rewards.items === undefined || subscription.rewards.items.length === 1
        }
    }

</script>
<div
        class="tab-panel"
        id="panel-{tabIndex}"
        role="tabpanel"
        tabindex={tabIndex}
>
    <h1>{title}</h1>
    <div class="grid {storeSection}">
        {#if storeSection === 'promos' }
            {#await $subscriptions.filter((s) => freeWebshopPacks.includes(s.bundleName)) then value}
                {#each value as subscription}
                    <FreePack subscription={subscription}/>
                {/each}
            {/await}
        {/if}

        {#if storeSection === 'subscriptions' || storeSection === 'resources'}
            {#await $subscriptions.filter((s) => !freeWebshopPacks.includes(s.bundleName)) then value}
                {#each value as subscription}
                    {#if subscriptionCondition(subscription) }
                        <SubscriptionPack subscription={subscription} isPurchased={true} storeSection={storeSection}/>
                    {/if}
                {/each}
            {/await}
        {/if}

        {#if $storePacks != null}
            {#await $storePacks.reduce((acc, pack) => {
                if (pack.storeSection === storeSection && (pack.isFeatured !== true || acc.foundFeatured)) {
                    acc.packs.push(pack);
                } else if (pack.isFeatured === true && !acc.foundFeatured) {
                    acc.foundFeatured = true;
                }
                return acc;
            }, {packs: [], foundFeatured: false}).packs}
                <Loader/>
            {:then value}
                {#each value as storePack}
                    {#if storeSection === 'subscriptions' || storeSection === 'resources'}
                        <SubscriptionPack subscription={storePack} isPurchased={false} storeSection={storeSection}
                                          on:buyPack={buyPack}/>
                    {:else if storeSection === 'vip'}
                        {#if storePack.bundleName.match(/lvl(\d+)pack/)[1] <= $vipLevel}
                            <StorePack {storePack} {storeSection} on:buyPack={buyPack}/>
                        {/if}
                    {:else if !storePack.luckyDraw}
                        <StorePack {storePack} {storeSection} on:buyPack={buyPack}/>
                    {/if}
                {/each}
            {/await}
        {/if}

        {#if storeSection === 'goldRush'}
            <GoldRushPack event={$rechargeRewardsEvent}/>
        {/if}

        {#if $storePacks != null && storeSection === 'giftFriend'}
            {#await $storePacks.filter((pack) => pack.buyForAFriend === true)}
                <Loader/>
            {:then unsortedPacks}
                {#each unsortedPacks.sort((a, b) => {
                    // If a has holidayTheme and b does not, a should come first
                    if (a.holidayTheme && !b.holidayTheme) return -1;
                    // If b has holidayTheme and a does not, b should come first
                    if (b.holidayTheme && !a.holidayTheme) return 1;
                    // If both have holidayTheme or neither have, maintain original order
                    return 0;
                }) as storePack}
                    <StorePack {storePack} {storeSection} on:buyPack={buyPack}/>
                {/each}
            {/await}
        {/if}

        {#if $storePacks != null && storeSection === 'luckyDraw'}
            {#await $storePacks.reduce((acc, pack) => {
                if (pack.luckyDraw && (pack.isFeatured !== true || acc.foundFeatured)) {
                    acc.packs.push(pack);
                } else if (pack.isFeatured === true && !acc.foundFeatured) {
                    acc.foundFeatured = true;
                }
                return acc;
            }, {packs: [], foundFeatured: false}).packs}
                <Loader/>
            {:then value}
                {#each value as storePack}
                    <StorePack {storePack} {storeSection} on:buyPack={buyPack}/>
                {/each}
            {/await}
        {/if}

        {#if $storePacks != null && storeSection === 'featured'}
            {#await $storePacks.filter(pack => pack.isFeatured && pack.storeSection && pack.items).slice(0, 1)}
                <Loader/>
            {:then value}
                {#each value as storePack}
                    <StorePack {storePack} {storeSection} on:buyPack={buyPack}/>
                {/each}
            {/await}
        {/if}
    </div>
</div>

<style lang="scss">
  @import '../styles/vars.scss';

  .tab-panel {
    text-align: center;

    h1 {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }

    .grid {
      padding-top: 10px;
      display: grid;
      grid-template-columns: repeat(
        auto-fit,
                      minmax($store-pack-width, $store-pack-width)
      );
      grid-auto-rows: $store-pack-height;
      grid-gap: 15px;
      place-items: center;
      justify-content: center;
      align-items: start;
      justify-items: center;

      &.goldRush {
        grid-auto-rows: $store-pack-height-gold-rush;
        grid-template-columns: repeat(auto-fit, minmax(877px, 877px));
      }

      &.goldPacks {
        grid-auto-rows: $store-pack-height-gold-packs;
      }

      &.resources {
        grid-auto-rows: $store-pack-height-resources;
      }

      &.subscriptions {
        grid-auto-rows: $store-pack-height-subscriptions;
      }

      &.giftFriend {
        grid-auto-rows: $store-pack-height-gift-friend;
      }

      &.luckyDraw {
        grid-auto-rows: $store-pack-height-lucky-draw;
      }

      @media (max-width: 700px) {
        grid-template-columns: repeat(
          auto-fit,
                        minmax($store-pack-width, 0.8fr)
        );
      }
    }
  }
</style>
