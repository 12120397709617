<script>
</script>

<div class="ribbon-wrapper">
  <span><slot /></span>
</div>

<style lang="scss">
  .ribbon-wrapper {
    position: absolute;
    left: -10px;
    top: -9px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    z-index: 20;

    span {
      font-size: 10px;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      line-height: 20px;
      transform: rotate(-45deg);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      width: 100px;
      display: block;
      background: #a71c0a;
      background: linear-gradient(#c9300d 0%, #a71c0a 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px;
      left: -21px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        z-index: -1;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #a70a0a;
      }

      &:before {
        left: 0px;
        border-left: 3px solid #a70a0a;
        border-right: 3px solid transparent;
      }

      &:after {
        right: 0%;
        border-right: 3px solid #a70a0a;
        border-left: 3px solid transparent;
      }
    }
  }
</style>
