<script>
    export let crumb = {};
    export let isLast = false;
</script>

<li class="crumb-wrapper">
    {#if crumb.canClick}
        <a href={crumb.url} class="crumb {crumb.selected ? 'selected' : ''}">{crumb.text}</a>
        {#if !isLast}
            <span class="crumb-arrow">></span>
        {/if}
    {:else}
        <span class="crumb {crumb.selected ? 'selected' : ''}">{crumb.text}</span>
        {#if !isLast}
            <span class="crumb-arrow">></span>
        {/if}
    {/if}
</li>

<style lang="scss">
  :global {
    .crumb-wrapper {
      word-break: break-word;

      .crumb, .crumb-arrow {
        font-size: clamp(1rem, 4vw, 14px);
        color: rgb(127, 100, 73);
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        font-family: var(--font-family-noto-sans);

        &.selected {
          color: rgb(255, 222, 123);
        }
      }

      .crumb-arrow {
        margin: 0 10px 0 8px;

        @media screen and (max-width: 375px) {
          margin: 0 6px 0 4px;
        }

        @media screen and (min-width: 768px) {
          margin: 0 8px 0 6px;
        }
      }
    }
  }
</style>
