<script>
    import {logMetric} from "./metrics.js";
    import {_} from "svelte-i18n";
    import {Button} from "svelte-chota";
    import {user} from './stores';

    function encodeMailtoBody(username, userId) {
        return encodeURIComponent(`\n\nUsername: ${username}\nID: ${userId}`);
    }

    function handleClick() {
        logMetric({button: 'delete_account'});

        let mailtoLink;
        if ($user) {
            const body = encodeMailtoBody($user.user.username, $user.user.id);
            mailtoLink = `mailto:support@war-and-peace.com?subject=[War and Peace] Account Deletion&body=${body}`;
        } else {
            mailtoLink = "mailto:support@war-and-peace.com?subject=[War and Peace] Account Deletion";
        }

        window.location.href = mailtoLink;
    }
</script>
<div class="delete-account">
    <h4>
        {$_('ContactDeleteAccountButton')}
    </h4>
    <ul>
        <li class="center"><b>Thank you for playing War and Peace, Commander!</b></li>
        <li>We hope your time commanding troops in the War and Peace world was filled with strategic victories and
            the forging of strong alliances. Having you as part of our community has been a pleasure, and we are
            genuinely sorry to see you go.
        </li>
        <li>If you wish to delete your account, you can initiate the process without the need to access the game
            itself.
        </li>
        <li>Our support team is available to <b>assist you</b> with the deletion process. Please remember that this
            action is permanent and cannot be undone.
        </li>
        <li> - Recurring Subscriptions: Make sure to review your recurring subscriptions before proceeding with the
            request. Check if you have any active subscriptions on <a
                    href="https://play.google.com/store/account/subscriptions">Google
                Play</a> or the <a href="https://m.onelink.me/38c819c8">AppStore</a>.
        </li>
        <li><b>Important Note:</b> Account deletion is permanent. Once deleted, your progress, in-game items, and
            any other game-related information cannot be recovered.
        </li>
        <li><b>We're here to help!</b> If you have any questions or need assistance with your War and Peace account,
            do not hesitate to contact our Support team <a href="mailto:support@war-and-peace.com">support@war-and-peace.com</a>
        </li>
    </ul>
    <div class="delete-account-button-wrapper">
        <Button
                class="delete-account-button orange"
                on:click={handleClick}
                primary
        >
            {$_('ContactDeleteAccountButton')}
        </Button>
    </div>
</div>
<style lang="scss">
  @import './styles/vars.scss';

  .delete-account {
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    h4 {
      font-size: 2.5rem;
      text-align: center;
      margin-top: 50px;
      color: #ffb900;
      font-weight: bold;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), 1px 1px 3px rgba(0, 0, 0, 0.5);
    }

    ul {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: 10px;
      list-style: none;
      width: 100%;

      li {
        font-size: 1.7rem;
        padding: 8px;

        &.center {
          text-align: center;
        }
      }
    }

    .delete-account-button-wrapper {
      max-width: 300px;
      margin: 20px auto;
    }
  }
</style>
