<script>
    import {_, locale} from 'svelte-i18n';
    import {researchTypes, researchTypesIcons} from "../../globals.js";
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_(gameItem.category + researchTypes[gameItem.type] + 'Title');
        itemDetails.description = $_(gameItem.category + researchTypes[gameItem.type] + 'Description');
        itemDetails.amount = item.amount;
        itemDetails.icon = researchTypesIcons[gameItem.type];
        itemDetails.rarity = 1;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
