<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function getSpriteName() {
        let quantity = gameItem.multiplier * 100;

        if (quantity == 70) {
            return 'march_boots_special';
        }

        return 'march_boots';
    }

    function setItemDetails() {
        itemDetails.title = $_('SpeedUpMarchTitleMin', {
            values: {PERCENT: gameItem.multiplier * 100},
        });
        itemDetails.description = $_('SpeedUpMarchDescriptionMin', {
            values: {PERCENT: gameItem.multiplier * 100},
        });
        itemDetails.amount = item.amount;
        itemDetails.icon = getSpriteName();
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
