<script>
    import {_} from "svelte-i18n";
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faXmark} from "@fortawesome/free-solid-svg-icons";
    import {createEventDispatcher} from "svelte";

    export let player = {};

    const dispatch = createEventDispatcher();

    function resetPlayer() {
        dispatch('resetPlayer');
    }
</script>
<div class="sending-to-wrapper">
    <span class="sending-to">{$_("SendingTo")}: </span>
    {#if player}
        <div class="player-wrapper">
            {#if player.allianceAcronym}
                <span class="player-clan">{'<' + player.allianceAcronym + '>'}</span>
            {/if}
            <span class="player-name">{player.name}</span>
            <a class="close-button" href="javascript:" on:click={resetPlayer}>
                <Fa fw icon={faXmark} primaryColor="#40260a" size="1.2x"/>
            </a>
        </div>
    {/if}
</div>

<style lang="scss">
  .sending-to-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
    width: 100%;
    max-width: 95%;

    .sending-to {
      font-size: clamp(1.2rem, 3vw, 20px);
      font-family: var(--font-family-noto-sans);
      color: rgb(229, 208, 163);
    }

    .player-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-width: 2px;
      border-color: rgb(255, 239, 131);
      border-radius: 6px;
      background-image: linear-gradient(0deg, rgb(255, 204, 65) 0%, rgb(225, 143, 1) 100%);
      box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.36), inset 0 0 13px 0 rgba(255, 238, 47, 0.8);
      font-size: clamp(1.2rem, 3vw, 20px);
      font-family: var(--font-family-noto-sans);
      color: rgb(64, 38, 10);
      padding: 0 5px 0 10px;
      gap: 4px;

      .close-button {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          opacity: 0.5;
          cursor: pointer;
          transition: transform 0.25s ease, opacity 0.25s ease;
          margin-left: 5px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
</style>
