<script>

</script>
<div class="tos">
    <h1>TERMS AND CONDITIONS OF USE</h1>
    <h3>1 THE AGREEMENT AND OUR SERVICES</h3>
    <ul>
        <li><b class="bg-warning">1.1</b> These terms and conditions (last updated on 24.07.2020)
            (<strong>“Terms”</strong>
            ) and all the documents expressly referred to in them (the <strong>”Agreement”</strong> ) are the terms and
            conditions of the agreement which you enter into with us when you use our War and Peace: Civil War game,
            including but not limited to any software, updated and upgrades, user manuals, other electronic materials
            and
            any copies of such software and materials(the <strong>“Game”</strong>).
        </li>
        <li><b class="bg-warning">1.2</b> Please read these Terms, together with all the documents referred to in it
            carefully before you access, install, start to use our Game. By accessing, downloading and/or using our
            Game,
            you indicate that you have read, understand and accept the terms and conditions of this Agreement and that
            you
            agree to abide by them each time you access and use our Game. If you do not agree to this Agreement, please
            refrain from accessing, downloading and/or using our Game. This Agreement applies to each user of our Game
            (each
            a <strong>“User”</strong>, <strong>“you”</strong>, <strong>“your”</strong> ).
        </li>
    </ul>
    <h3>2 INFORMATION ABOUT US</h3>
    <p>War and Peace: Civil War is a virtual game, an application provided by eRepublik Labs Ltd (<strong>“we”</strong>
        ,<strong>“us”</strong> and <strong>“our”</strong> ). eRepublik Labs Ltd is incorporated in Ireland under company
        number 462101 with registered office at 2nd Floor, Palmerston House, Denzille Lane, Dublin 2, D02 WD37, Ireland.
    </p>
    <h3>3 YOUR ACCOUNT</h3>
    <ul>
        <li>
            <b class="bg-warning">3.1</b> You may not sublicense, rent, lease, sell, trade, gift or otherwise transfer
            your
            account or use an account obtained in any of these ways.
        </li>
        <li>
            <b class="bg-warning">3.2</b> Access to our Game may also be permitted via third parties’
            applications/websites
            which may imply that in order to access our Game, you must be have a valid account on third parties’
            Websites/Applications through which you will connect to our Game. Creating an account with any third parties
            is
            regulated by the Terms of Services and Privacy Policies provided by the respective third parties and all
            such
            documents form a separate contract to this Agreement. We are not responsible and expressly exclude our
            liability
            for any loss or damage suffered by you arising from the use of any third parties websites and/or
            applications.
            Please read the terms and conditions of any third party website/application carefully.
        </li>
    </ul>
    <h3>4 ACCESSING OUR GAME</h3>
    <ul>
        <li><b class="bg-warning">4.1</b> Subject to this Agreement, you may only use the Game for your own personal and
            non
            commercial use;
        </li>
        <li><b class="bg-warning">4.2</b> As a condition of your access and use of our Game, you warrant to us that you
            have
            the right, authority and capacity to enter into and be bound by this Agreement.
        </li>
        <li>
            <b class="bg-warning">4.3</b> You must be over 16 years old to use the Game. Persons under 16 years of age
            are
            not entitled to use our Game. The use of the Game by a minor between the age of 16 and 18 is subject to the
            consent of their parent or guardian. By using the Game, you declare that you are over 16 and/or that you
            have
            obtained the necessary consent to use the Game in compliance with this agreement. We reserve the right to
            close
            any user account if such consent has not been granted or if you cannot establish that it has not been
            granted to
            our satisfaction. We advise parents or guardians who permit their children to use the Game that it is
            important
            that they communicate with their children about their safety online, as moderation is not foolproof. Minors
            who
            are using any interactive service should be made aware of the potential risks to them by their parents or
            guardians.
        </li>
        <li>
            <b class="bg-warning">4.4</b> We have no obligation to monitor or moderate any user’s activity or use of our
            Game, however we retain the right at all times to monitor, retain and disclose any information as necessary
            to
            satisfy any applicable law, regulation, legal process or regulatory authority request.
        </li>
        <li>
            <b class="bg-warning">4.5</b> We do encourage that you contact us at <a
                href="mailto:support@war-andpeace.com">support@war-and-peace.com</a>,
            an email which allows you to report any Content that is in breach of this Agreement or in any other way
            objectionable and to notify moderators of any abuses or breaches of this Agreement.
        </li>
        <li>
            <b class="bg-warning">4.6</b> Access to our Game is permitted on a temporary basis, and we reserve the right
            to
            at any time and without liability withdraw, suspend or amend any aspect or feature of the Game without
            notice or
            liability. Access to our Game is provided on an “as is” basis. You should not rely on any Content and/or
            other
            material on our Game to make (or refrain from making) any decision or to take (or refrain from taking) any
            action on which reliance should be placed. We disclaim all liability and responsibility arising from any
            reliance placed on such Content and/or materials by any user of our Game, or by anyone who may be informed
            of
            any of the Contents. We accept no responsibility for keeping the information in the Game up to date or
            complete
            or liability for any failure to do so.
        </li>
        <li>
            <b class="bg-warning">4.7</b> When using our Game, you must comply with the provisions of our Privacy Policy
            which forms part of this Agreement. Our Privacy Policy sets out how we may use information about you
            collected
            during use of our Game.
        </li>
    </ul>
    <h3>5 RESTRICTIONS ON RIGHT TO USE</h3>
    <ul>
        <li>
            <b class="bg-warning">5.1</b> You agree that you shall not (and you agree not to allow any third party to):
        </li>
        <ul>
            <li>
                <b class="bg-warning">5.1.1</b> modify, adapt, translate, or reverse engineer any portion of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.2</b> remove any copyright, trade mark or other proprietary rights notices
                contained in or on the Game or on any Content or other material obtained via the Game.
            </li>
            <li>
                <b class="bg-warning">5.1.3</b> use any robot, spider, search/retrieval application, or other automated
                device, process or means to access, retrieve or index any portion of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.4</b> access, screen-scrape, retrieve or index any portion of the Game for
                purposes of constructing or populating a searchable database of any of the information or Content ;
            </li>
            <li>
                <b class="bg-warning">5.1.5</b> reformat or frame any portion of the Content part of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.6</b> create user accounts by automated means or under false or fraudulent
                pretences;
            </li>
            <li>
                <b class="bg-warning">5.1.7</b> create or transmit unwanted electronic communications such as “spam”, or
                make excessive challenges or invitations, to other users or otherwise interfere with other users
                enjoyment
                of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.8</b> use the Game to violate the security of any network, crack passwords or
                security encryption codes, transfer or store illegal material including that are deemed threatening or
                obscene;
            </li>
            <li>
                <b class="bg-warning">5.1.9</b> copy or store any Content offered on the Games for other than your own
                use
                of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.10</b> use any device, software or routine that interferes with the proper
                working
                of the Game, or otherwise attempt to interfere with the proper working of the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.11</b> take any action that imposes, or may impose in our sole discretion, an
                unreasonable or disproportionately large load on our IT infrastructure;
            </li>
            <li>
                <b class="bg-warning">5.1.12</b> use the Game, intentionally or unintentionally, to violate any
                applicable
                law;
            </li>
            <li>
                <b class="bg-warning">5.1.13</b> collect or store personal data about other users in connection with the
                prohibited activities described in this paragraph;
            </li>
            <li>
                <b class="bg-warning">5.1.14</b> engage in or promote any surveys, contests, pyramid schemes, chain
                letters,
                unsolicited e-mailing or spamming via the Game;
            </li>
            <li>
                <b class="bg-warning">5.1.15</b> use the Game for advertising, marketing, charitable, campaigning, for
                public relations or political purposes of any kind;
            </li>
            <li>
                <b class="bg-warning">5.1.16</b> impersonate any person or entity;
            </li>
            <li>
                <b class="bg-warning">5.1.17</b> post, e-mail, transmit or otherwise make available using the Game any
                material that you do not have a right to make available under any law or contractual obligation which
                includes a breach of third party intellectual property rights.
            </li>
        </ul>
    </ul>
    <h3>
        6 CONTENT STANDARDS AND LICENCE
    </h3>
    <ul>
        <li>
            <b class="bg-warning">6.1</b> These provisions apply to any and all Content which you contribute to our
            Game,
            and to any interactive services associated with it.
        </li>
        <li>
            <b class="bg-warning">6.2</b> You must comply with the spirit of the following standards as well as the
            letter.
            The standards apply to each part of any Content as well as to its whole.
        </li>
        <li>
            <b class="bg-warning">6.3</b> You are solely responsible for any Content that you submit on the Game or
            transmit
            to other users.
        </li>
        <li>
            <b class="bg-warning">6.4</b> You are solely responsible for all information or communications published on
            our
            Game. You understand that all information publicly posted or privately transmitted through the Game is the
            sole
            responsibility of the person from which such content originated and that we will not be liable for any
            errors or
            omissions in any content or as a result of any user’s use of the Game. You understand that we cannot
            guarantee
            the identity of any other users with whom you may interact in the course of using the Game. Additionally, we
            cannot guarantee the authenticity of any data provided by other users or relationships they may describe.
        </li>
        <li>
            <b class="bg-warning">6.5</b> The following is a partial list of the type of Content that is prohibited on
            the
            Game. Content must not:
        </li>
        <ul>
            <li>
                <b class="bg-warning">6.5.1</b> defame, abuse, bully, harass, stalk, threaten or otherwise offend
                others;
            </li>
            <li>
                <b class="bg-warning">6.5.2</b> be unlawful, obscene, defamatory, seditious, indecent, offensive, liable
                to
                incite racial hatred, liable to incite violence, discriminatory, menacing, scandalous, inflammatory, be
                in
                breach of confidence, be in breach of privacy or be inappropriate;
            </li>
            <li>
                <b class="bg-warning">6.5.3</b> breach any applicable laws or regulations;
            </li>
            <li>
                <b class="bg-warning">6.5.4</b> deceive or mislead any person and/or entity;
            </li>
            <li>
                <b class="bg-warning">6.5.5</b> upload, post, email, transmit or otherwise make available any content or
                initiate communications which include information that you do not have the right to disclose or make
                available under any law or under contractual or fiduciary relationships (such as insider information, or
                proprietary and confidential information learned or disclosed as part of employment relationships or
                under
                nondisclosure agreements);
            </li>
            <li>
                <b class="bg-warning">6.5.6</b> upload, post, email, transmit or otherwise make available any content or
                initiate communication that infringes upon patents, trademarks, trade secrets, copyrights or other
                proprietary rights;
            </li>
            <li>
                <b class="bg-warning">6.5.7</b> post, email, transmit or otherwise make available any unsolicited or
                unauthorized advertising, promotional materials,<strong>“junk mail,”</strong> <strong>“spam,”</strong>
                <strong>“chain letters,”</strong> <strong>“pyramid schemes,”</strong> or any other form of solicitation;
            </li>
            <li>
                <b class="bg-warning">6.5.8</b> post, email, transmit or otherwise make available any material that
                contains
                software viruses or any other computer code, files or programs designed to interrupt, destroy or limit
                the
                functionality of any software or hardware or telecommunications equipment;
            </li>
            <li>
                <b class="bg-warning">6.5.9</b> interfere with or disrupt the Game or servers or networks connected to
                the
                Game, or disobey any requirements, procedures, policies or regulations of networks connected to the
                Game;
                and/or
            </li>
        </ul>
    </ul>
    <h3>
        7 SUSPENSION AND TERMINATION
    </h3>
    <ul>
        <li>
            <b class="bg-warning">7.1</b> You agree that we may, with or without cause, immediately terminate your user
            account and access to our Game without prior notice at our sole discretion. Without limiting the foregoing,
            the
            following may lead to a termination by us of a user’s use of the Game: (a) a breach of this Agreement, (b) a
            request by law enforcement authorities, gardai /police or other government agencies in respect of your
            Account,
            (c) a request by you (self-initiated account deletions), (d) unexpected technical issues or problems
            experienced
            by us, and (e) extended periods of inactivity in respect of your Account. Termination of your user account
            includes removal of access to our Game, all Content that we may determine and may also bar you from further
            use
            of the Game. Furthermore, you agree that all terminations shall be made in our sole discretion and that we
            shall
            not be liable to you nor any third party for any termination of your account or access to the Game.
        </li>
        <li>
            <b class="bg-warning">7.2</b> You understand and agree that we may review and delete any Content that in our
            sole judgment breach this Agreement or which might be offensive, illegal, or that might violate the rights
            of,
            harm, or threaten the safety of other or us. We reserve the right to investigate and we will determine, in
            our
            discretion, whether there has been a breach of this Agreement through your use of our Game. When a breach of
            this agreement has occurred, we may take such action as we deem appropriate.
        </li>
        <li>
            <b class="bg-warning">7.3</b> Failure to comply with this Agreement may result in our taking all or any of
            the
            following actions:
        </li>
        <ul>
            <li>
                <b class="bg-warning">7.3.1</b> immediate, temporary or permanent withdrawal of your right to use our
                Game;
            </li>
            <li>
                <b class="bg-warning">7.3.2</b> immediate, temporary or permanent removal of any posting, material or
                Content uploaded by you to our Game;
            </li>
            <li>
                <b class="bg-warning">7.3.3</b> issue of a public (via the Game) or private warning to you;
            </li>
            <li>
                <b class="bg-warning">7.3.4</b> limit the amount of postings you may make;
            </li>
            <li>
                <b class="bg-warning">7.3.5</b> legal proceedings against you for reimbursement of all costs on an
                indemnity
                basis (including, but not limited to, reasonable administrative and legal costs) resulting from the
                breach;
            </li>
            <li>
                <b class="bg-warning">7.3.6</b> other legal action against you; and/or
            </li>
            <li>
                <b class="bg-warning">7.3.7</b> disclosure of such information to law enforcement authorities as we
                reasonably feel is necessary.
            </li>
        </ul>
        <li>
            <b class="bg-warning">7.4</b> We exclude liability for actions taken in response to breaches of the content
            standards and restrictions of use. The responses described in this Agreement are not limited, and we may
            take
            any other action we reasonably deem appropriate.
        </li>
    </ul>
    <h3>
        8 COMPLAINTS
    </h3>
    <p>
        If you believe that Content posted on the Game breaches this Agreement or is in any other way objectionable,
        please
        notify us by email at support@war-and-peace.com by specifying the full details of such Content and identifying
        all
        postings of such Content and specifying the reasons for your objections. If you do not provide us with
        sufficient
        information, we may be unable to process your complaint. We reserve the right to investigate and we will
        determine,
        in our discretion, what action (if any) to take. You are solely responsible for your interactions with other
        users
        of the Game. While we reserve the right to monitor or to intervene in disputes between you and other users, we
        have
        no obligation to do so.
    </p>
    <h3>
        9 DISPUTES WITH THIRD PARTIES
    </h3>
    <p>
        You must tell us immediately if anyone makes or threatens to make any claim or issue legal proceedings against
        you
        relating to your use of the Game. You will, at our request, immediately stop the act complained of. If we ask
        you
        to, you must confirm the details of the claim in writing. If you fail to stop the act or acts complained of we
        reserve the right to take any and all appropriate action against you under the terms of the Agreement.
    </p>
    <h3>
        10 INTELLECTUAL PROPERTY RIGHTS
    </h3>
    <ul>
        <li>
            <b class="bg-warning">10.1</b> We are the owner or the licensee of all intellectual property rights in our
            Game,
            and in the Content published on it. Those works are protected by copyright laws, trade mark laws, database
            laws
            and treaties around the world. All such rights are reserved.
        </li>
        <li>
            <b class="bg-warning">10.2</b> The Game (including, but not limited to, text, photographs, graphics, video
            and
            audio Content) is protected by copyright as collective works or compilations under the copyright laws of
            Ireland
            and other countries. Except for the Content submitted by users, the Game and all aspects thereof, including
            all
            copyrights, trade marks, and other intellectual property or proprietary rights therein, is owned by us or
            our
            licensors. You acknowledge that the Game and any underlying technology or software used in connection with
            the
            Game contains our proprietary information. You may not modify, reproduce, distribute, create derivative
            works
            of, publicly display or in any way exploit, any of the content, software, and/or materials available on the
            Game, in whole or in part except as expressly provided. Except as expressly and unambiguously provided
            herein,
            we and our suppliers do not grant you any express or implied rights, and all rights in any of the elements
            of
            the Game not expressly granted by us to you are retained by us.
        </li>
        <li>
            <b class="bg-warning">10.3</b> If it is determined that you retain moral rights in the Content, you hereby
            declare that (a) you do not require that any personally identifying information be used in connection with
            the
            Content, or any derivative works of or upgrades or updates thereto; (b) you have no objection to the
            publication, use, modification, deletion and exploitation of the Content by us or our licensees, successors
            and
            assigns, or on other Games; (c) you forever waive and agree not to claim or assert any entitlement to any
            and
            all moral rights of an author in any of the Content; and (d) you forever release us, and our licensees,
            successors and assigns, from any claims that you could otherwise assert against us by virtue of any such
            moral
            rights.
        </li>
        <li>
            <b class="bg-warning">10.4</b> All product names mentioned on our Game are the trade marks of their
            respective
            owners, and other trade marks may be displayed on our Game from time to time. Some material on our Game may
            contain other information containing intellectual property of a third party. Nothing displayed on our Game
            should be construed as granting you any license or right of use of any logo, information or trade mark
            displayed
            on it, without the express written permission of the relevant owner, save as expressly provided in this
            Agreement.
        </li>
        <li>
            <b class="bg-warning">10.5</b> You may print off one copy, and may download extracts, of any page(s) from
            our
            Game for your own personal purposes and you may draw the attention of others within your organization to
            material posted on our Game. In particular, you must not cache any of the contents for access by third
            parties,
            nor mirror, scrape or frame any of the content of the Game, nor incorporate it into another website,
            software or
            application without our express written permission except as permitted by us.
        </li>
        <li>
            <b class="bg-warning">10.6</b> You must not modify the paper or digital copies of any materials you have
            printed
            off or downloaded in any way, and you must not use any illustrations, photographs, or any graphics
            separately
            from any accompanying text.
        </li>
        <li>
            <b class="bg-warning">10.7</b> Our status (and that of any partners, advertisers or third parties identified
            on
            our Game) as the authors of material on our Game must always be acknowledged by you.
        </li>
        <li>
            <b class="bg-warning">10.8</b> You must not use any part of the Content on our Game for commercial or
            non-commercial purposes without obtaining a license to do so from us.
        </li>
        <li>
            <b class="bg-warning">10.9</b> If you print off, copy or download any part of our Game in breach of this
            Agreement, your right to use our Game will cease immediately and you must, at our option, return or destroy
            any
            copies of the materials that you have made.
        </li>
    </ul>
    <h3>
        11 VIRTUAL ITEMS
    </h3>
    <ul>
        <li>
            <b class="bg-warning">11.1</b> Subject to this Agreement, you are granted a limited license right to use
            in-game
            virtual items, which are available for purchase or free distribution at our discretion. You agree that the
            in-
            game virtual items are not redeemable for money or money’s worth at any time from us, another user of our
            game
            or a third party. You acknowledge that you are not entitled to claim refunds from us for any in-game virtual
            item purchased from us by you, other than where we have exercised our right to terminate your user account
            and
            termination was not caused by your breach of this Agreement. For the avoidance of doubt you are never
            entitled
            to a refund from us for any in-game virtual item given to you by us for free or given to you by another user
            of
            the Game. You acknowledge that we reserve the right to regulate, modify, withdraw, suspend and otherwise
            control
            the in-game virtual items and the use of such items as we see fit and you agree that we will have no
            liability
            to you by reason of our regulation, modification, withdrawal, suspension and/or control of in-game virtual
            items.
        </li>
        <li>
            <b class="bg-warning">11.2</b> <strong>You may not</strong> sublicense, rent, lease, sell, trade,
            <strong>gift</strong> or otherwise transfer <strong>in-game virtual items of the specific in-game actions
            permitted by the game’s gameplay</strong> and you cannot use virtual items obtained in any of the ways
            mentioned
            in this paragraph. You acknowledge that we may, at any time and at our sole discretion, add new services for
            additional fees and charges, or amend fees and charges for existing services and may determine that any fees
            and
            charges.
        </li>
        <li>
            <b class="bg-warning">11.3</b> In order to buy in-game virtual items, you can either access the in-game
            "shop"
            section or the browser "webshop" located at webshop.war-and-peace.com, choose the items you wish to buy,
            choose
            a payment method and acknowledge your acceptance of this Agreement by clicking the Login button. According
            to
            the chosen method of payment, you will then be transferred to the Website or payment platform of the
            relevant
            payment provider, from which point you must follow the instructions given by that payment provider. You
            agree
            and acknowledge that the terms and conditions imposed by the relevant payment provider in respect of your
            transaction form a separate contract to this Agreement. We are not responsible and expressly exclude our
            liability for any loss or damage suffered by you arising from the use of the websites of any payment
            providers
            in connection with Us. Please read the terms and conditions of any third party website carefully.
        </li>
    </ul>
    <h3>
        12 OUR LIABILITY
    </h3>
    <ul>
        <li>
            <b class="bg-warning">12.1</b> The Content and other material displayed, accessible from or used on our Game
            are
            provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by
            law, we
            expressly exclude:
        </li>
        <ul>
            <li>
                <b class="bg-warning">12.1.1</b> all conditions, warranties and other terms which might otherwise be
                implied
                by statute, common law or the law of equity; and
            </li>
            <li>
                <b class="bg-warning">12.1.2</b> any liability for any direct, indirect or consequential loss or damage
                incurred by any user in connection with our Game or in connection with the use, inability to use, or
                results
                of the use of our Game, any websites/applications linked to it and any materials posted on it,
                including,
                without limitation any liability for loss of income or revenue, loss of business, loss of profits or
                contracts, loss of anticipated savings, loss of data, loss of goodwill, wasted management or office time
                and
                for any other loss or damage of any kind, however arising and whether caused by tort (including
                negligence),
                breach of contract or otherwise, even if foreseeable.
            </li>
        </ul>
        <li>
            <b class="bg-warning">12.2</b> This does not affect our liability for death or personal injury arising from
            our
            negligence, or our liability for fraudulent misrepresentation or misrepresentation as to a fundamental
            matter,
            or any other liability which cannot be excluded or limited under applicable law.
        </li>
        <li>
            <b class="bg-warning">12.3</b> We shall not be liable for any failure to perform any of our obligations
            under
            this Agreement caused by matters beyond our reasonable control. Under no circumstances will we be liable in
            any
            way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or
            damage of any kind incurred as a result of the use of, access to, or denial of access to any Content on the
            Game.
        </li>
        <li>
            <b class="bg-warning">12.4</b> 12.4 We will not be liable for any loss or damage caused by a distributed
            denial-of-service attack, viruses, malicious or impairing computer programs or other technologically harmful
            material that may infect your equipment or device, computer programs, data or other proprietary material due
            to
            your use of our Game or to your downloading of any material posted on it, or on any website/application
            linked
            to it.
        </li>
        <li>
            <b class="bg-warning">12.5</b> We do not guarantee the security of the services or any systems connected
            with
            the use of the Game (including the internet and your hardware and software) used in accessing the services,
            or
            any information passed through such systems. We do not guarantee access to the Game or any systems used in
            accessing our services will be continuous or virus or error free.
        </li>
        <li>
            <b class="bg-warning">12.6</b> Any material downloaded or otherwise obtained through the use of the Game is
            done
            at your own discretion and risk and that you will be solely responsible for any damage to your
            computer/device
            or loss of data that results from the download of any such material. No advice or information, whether oral
            or
            written, obtained by you from us or through or from the Game shall create any warranty not expressly stated
            in
            this Agreement.
        </li>
    </ul>
    <h3>
        13 INDEMNITY
    </h3>
    <p>
        You agree to indemnify and hold us, our parent, subsidiaries, officers, directors, shareholders and employees,
        harmless, including costs and legal fees, from any claim or demand made by any third party due to or arising out
        of
        (i) your access to Game, (ii) your use of any of our Services, (iii) the violation of this Agreement by you, or
        (iv)
        the infringement by you, or any third party using your account or user identification name or password, of any
        intellectual property or other right of any person or entity.
    </p>
    <h3>
        14 CONTACT FROM US
    </h3>
    <p>
        In the course of providing you services and in respect of your use of the Game, we may need to communicate with
        you
        via email or the other details that you have submitted to us directly or through different applications that
        link
        third parties’ websites or applications to our Game. You agree to receive emails which are specific to your
        account
        and necessary for the normal functioning of the Game, including emails which help inform users about
        functionality
        of the Game. You also agree to receive emails containing information regarding new games and/or applications
        developed by our company or any of our partners and/or affiliates.
    </p>
    <h3>
        15 JURISDICTION AND APPLICABLE LAW
    </h3>
    <p>
        The Irish courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to our
        Game
        although we retain the right to bring proceedings against you for breach of this Agreement in your country of
        residence or any other relevant country. This Agreement is governed by Irish law. The rights and remedies
        contained
        in this Agreement are cumulative and not exclusive of rights or remedies provided by law.
    </p>
    <h3>
        16 VARIATIONS
    </h3>
    <p>
        We may revise this Agreement at any time by amending the pages upon which they appear on our Game without giving
        notice of such modifications. Such modifications become effective immediately upon posting of the modified terms
        on
        the Game. You agree to review the Agreement periodically so that you are aware of any modifications. Your
        continued
        use of the Game after any modifications indicates your acceptance of the modified Agreement.
    </p>
    <h3>
        17 WAIVER
    </h3>
    <p>
        A failure to exercise or delay in exercising a right or remedy provided by this Agreement or by law does not
        constitute a waiver of the right or remedy or a waiver of other rights or remedies. No single or partial
        exercise of
        a right or remedy provided by this Agreement or by law prevents further exercise of that right or remedy or the
        exercise of another right or remedy.
    </p>
    <h3>
        18 SEVERABILITY
    </h3>
    <p>
        In the event that any clause or any part of any clause in this Agreement is declared invalid or unenforceable,
        by
        the judgment or decree by consent or otherwise of a court of competent jurisdiction from whose decision no
        appeal is
        or can be taken, all other provisions contained in this agreement shall remain in full force and effect and
        shall
        not be affected by such finding for the term of this Agreement.
    </p>
    <h3>
        19 ASSIGNMENT AND ENTIRE AGREEMENT
    </h3>
    <ul>
        <li>
            <b class="bg-warning">19.1</b> We may assign or subcontract any or all of our rights and obligations of our
            Agreement with you to a third party at any time, at our discretion. You may not, without our prior written
            consent, assign or dispose of any of your rights or obligations arising under this Agreement.
        </li>
        <li>
            <b class="bg-warning">19.2</b> This Agreement contains the entire agreement and understanding between the
            parties relating to our Game, and supersedes any and all prior agreements, arrangements, statements and
            understandings, except for any fraud or fraudulent representation by either you or us. In the event of any
            inconsistency between the Privacy Policy and these Terms then these Terms take priority.
        </li>
    </ul>
    <h3>
        20 SUBMISSIONS
    </h3>
    <p>
        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback and other information about
        the
        Game provided by you to us are non-confidential and may be used by us at our discretion.
    </p>
    <h3>
        21 YOUR CONCERNS AND COMPLAINTS
    </h3>
    <p>
        If you have any concerns about material that appears on our Game, please contact us at <a
            href="mailto:support@war-and-peace.com">support@war-and-peace.com</a>.
    </p>
</div>
<style lang="scss">
  @import './styles/vars.scss';

  .tos {
    padding: 20px;

    ul {
      list-style-type: none;
    }
  }
</style>
