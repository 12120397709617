<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";
    import {itemRarity} from "../../constants";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_(
            'CommanderUniversalTalent' + itemRarity[gameItem.rarity] + '_Title',
        );
        itemDetails.description = $_(
            'CommanderUniversalTalent' + itemRarity[gameItem.rarity] + '_TooltipDescription',
        );
        itemDetails.icon = 'commander_universal_talent_' + itemRarity[gameItem.rarity].toLowerCase();
        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
