<script>
    import {_} from 'svelte-i18n';
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faCircleCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
    import {Button, Card} from 'svelte-chota';
    import {onMount} from "svelte";
    import {getAnalytics, logEvent} from 'firebase/analytics';
    import {logMetric} from "../metrics";
    import ItemsListing from "./ItemsListing.svelte";
    import {claim_gift_route} from "../constants";
    import {resourceDetails} from "../globals.js";

    export let _systemMessages;
    export let system_message_modal_hide;
    const analytics = getAnalytics();

    export let globals = {};

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;

    let message;
    let processing = false;
    let loaded = false;
    let claimed = false;

    onMount(async () => {
        message = Object.values(_systemMessages)[0];
        await logMetric({show_system_message: "true"});

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'system_message_modal',
            firebase_screen_class: 'web',
        });
        loaded = true;
    });

    async function claimGift() {
        try {
            processing = true;
            const response = await fetch(claim_gift_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    messageId: message.id,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.text();
            claimed = true;
            // console.log(res)

        } catch (error) {
            Rollbar.error("Error claiming gift " + error)
        } finally {
            processing = false;
        }
    }
</script>
{#if loaded}
    <Card class="system-message animate__animated animate__bounceInDown">
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a class="close-button" href="javascript:" on:click={system_message_modal_hide}>
            <Fa fw icon={faXmark} primaryColor="#240c05" size="2x"/>
        </a>
        <h1>{message.subject}</h1>
        <h4>{message.body}</h4>
        <ItemsListing storePack={{}} goldAmount={0} globals={globals} items={message.items} hasMask={false}/>
        {#if claimed}
            <Fa icon={faCircleCheck} fw size="3x" class="claimed-mark animate__animated animate__heartBeat"
                primaryColor="green"/>
        {:else}
            <Button
                    primary
                    loading={processing}
                    class="claim-button green"
                    on:click={claimGift}
            >
                {$_('Claim')}
            </Button>
        {/if}
    </Card>
{/if}

<style lang="scss">
  :global {
    .system-message {
      &.modal {
        min-width: 450px !important;
      }

      @media (max-width: 700px) {
        &.modal {
          min-width: 350px !important;
        }
      }

      &.card {
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: center;
        overflow: hidden;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
        transition: all 0.2s ease-in;
        position: relative;


        h1 {
          font-weight: 700;
          font-family: var(--font-family-sans-condensed);
          padding: 1rem 2rem;
          line-height: 1.2em;
          margin-bottom: 0;
          margin-top: 0;
        }

        h4 {
          font-family: var(--font-family-sans-condensed);
          max-width: 400px;
          font-size: 16px;
          color: #8c7138;
          margin: 10px auto;
        }

        .claim-button, .claimed-mark {
          margin: 20px 0;
        }

        .items {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 6px;
          padding: 10px;
          margin: 0;
          width: auto;
        }

        .close-button {
          position: absolute;
          top: 0;
          right: 0;
          padding: 7px 2px;
        }
      }

    }
  }
</style>