<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;
    export let globals;

    export let itemDetails = {};

    function getSpriteName() {
        if (
            gameItem.category == 'troopAttackBooster' ||
            gameItem.category == 'troopDefenseBooster'
        ) {
            if (gameItem.seconds == 7200) {
                return gameItem.category + '_special';
            }
        }

        return gameItem.category;
    }

    function setItemDetails() {
        itemDetails.title = $_(gameItem.category + 'ItemTitle', {
            values: {
                TIME: globals.formatTimeFriendly(gameItem.seconds),
                PERCENT: gameItem.bonusValue,
            },
        });
        itemDetails.description = $_(gameItem.category + 'ItemDescription', {
            values: {
                TIME: globals.formatTimeFriendly(gameItem.seconds),
                PERCENT: gameItem.bonusValue,
            },
        });
        itemDetails.amount = item.amount;
        itemDetails.icon = getSpriteName();
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
