<script>
    import {_, locale, number} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_('PrisonCampInstantLiberationPassPackItemTitle', {
            values: {AMOUNT: $number(gameItem.amount)},
        });
        itemDetails.description = $_(
            'PrisonCampInstantLiberationPassPackItemDescription',
        );
        itemDetails.icon = 'prison-camp-instant-release';
        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
