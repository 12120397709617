<script>
    import {onMount, createEventDispatcher} from 'svelte';
    import {fade} from 'svelte/transition';
    import {logMetric} from '../metrics';
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faBell, faBellSlash} from '@fortawesome/free-solid-svg-icons';
    import {messaging, isSupported} from '../notifications';
    import {deleteToken, getToken} from 'firebase/messaging';
    import {
        get_notifications_settings_route,
        refresh_notifications_token,
        update_notifications_settings_route,
    } from '../constants.js';

    const dispatch = createEventDispatcher();
    export let showEditIcon = true;
    let settingsShown = false;
    let permission = typeof Notification !== 'undefined' ? Notification.permission : 'unsupported';
    let notificationsEnabled = permission === 'granted';
    let specialOffersEnabled = false;
    let freeGiftsEnabled = false;
    let notificationsAreEnabled = false;
    let notificationsNotSupported = false;

    function areNotificationsEnabled() {
        notificationsAreEnabled = !!((notificationsEnabled && specialOffersEnabled && freeGiftsEnabled) ||
            (notificationsEnabled && specialOffersEnabled) ||
            (notificationsEnabled && freeGiftsEnabled));
    }

    async function requestPermission() {
        try {
            // Register the service worker before requesting permission
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('Service Worker registered with scope:', registration.scope);
            await Notification.requestPermission();
            permission = Notification.permission;
            notificationsEnabled = permission === 'granted';
            await logMetric({action: 'request_permission_notifications', result: permission});
            if (permission === 'granted') {
                const token = await getToken(messaging, {serviceWorkerRegistration: registration});
                console.log('FCM Token:', token);
                // Send the token to your server to store it
                setTopics(token);
            }
        } catch (err) {
            console.error('Unable to get permission to notify:', err);
        }
    }

    function updateNotificationsSettings(topicsToUpdate) {
        fetch(update_notifications_settings_route, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tokenId: localStorage.getItem('FCMToken'),
                topics: topicsToUpdate.topics,
            }),
        })
            .then((response) => {
                console.log('Update notifications settings response:', response);
            })
            .catch((error) => Rollbar.error('Update notifications settings error:' + error));
    }

    function closeSettings() {
        const currentTopics = localStorage.getItem('topics');
        let topicsToUpdate = {
            topics: [],
        };
        if (specialOffersEnabled) {
            topicsToUpdate.topics.push('wsSt200');
        }
        if (freeGiftsEnabled) {
            topicsToUpdate.topics.push('wsFnFreeSub', 'wsStFreeSub');
        }
        if (permission !== 'granted' || arraysAreEqual(JSON.parse(currentTopics), topicsToUpdate.topics)) {
            console.log('No changes in topics');
        } else {
            updateNotificationsSettings(topicsToUpdate);
            localStorage.setItem('topics', JSON.stringify(topicsToUpdate.topics));
            logMetric({action: 'notifications_close_settings', topics: topicsToUpdate.topics});
        }
        settingsShown = false;
    }

    function arraysAreEqual(arr1, arr2) {
        // Check if arrays have the same length
        if (arr1.length !== arr2.length) {
            return false;
        }
        // Create copies of the arrays and sort them
        let sortedArr1 = arr1.slice().sort();
        let sortedArr2 = arr2.slice().sort();
        // Compare the sorted arrays element by element
        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }
        return true;
    }

    function enableNotificationsAgain() {
        if (!notificationsAreEnabled) {
            // Disable special offers and free gifts notifications
            specialOffersEnabled = false;
            freeGiftsEnabled = false;
            notificationsEnabled = false;
            logMetric({action: 'disable_notifications'});
        } else {
            // Enable notifications
            notificationsEnabled = true;
            specialOffersEnabled = true;
            freeGiftsEnabled = true;
            logMetric({action: 'enable_notifications_again'});
            if (permission !== 'granted') requestPermission();
        }
    }

    async function generateNewToken() {
        try {
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            const currentToken = await getToken(messaging, {serviceWorkerRegistration: registration});
            // Delete the existing token
            await deleteToken(messaging);
            // Generate a new token
            const newToken = await getToken(messaging, {serviceWorkerRegistration: registration});
            localStorage.setItem('FCMToken', newToken);
            // Set the FCMTokenExpiry to the current timestamp
            const currentTimestamp = Date.now();
            localStorage.setItem('FCMTokenExpiry', currentTimestamp.toString());
            console.log('Current FCM Token:', currentToken);
            console.log('New FCM Token:', newToken);
            // Ensure the fetch request runs after the new token is obtained
            await fetch(refresh_notifications_token, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    oldTokenId: currentToken,
                    newTokenId: newToken,
                }),
            })
                .then((response) => response.json())
                .catch((error) => Rollbar.error('Refresh notifications token error:' + error));
        } catch (err) {
            console.error('Unable to get FCM token:', err);
        }
    }

    function shouldGenerateNewToken() {
        const FCMTokenExpiry = localStorage.getItem('FCMTokenExpiry');
        if (!FCMTokenExpiry) {
            return true;
        }
        const expiryTimestamp = parseInt(FCMTokenExpiry, 10);
        const currentTimestamp = Date.now();
        const twoMonthsInMilliseconds = 2 * 30 * 24 * 60 * 60 * 1000; // Approximation of 2 months
        return currentTimestamp - expiryTimestamp > twoMonthsInMilliseconds;
    }

    function setTopics(token) {
        fetch(get_notifications_settings_route, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tokenId: token,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                localStorage.setItem('FCMToken', token);
                // Set the FCMTokenExpiry to the current timestamp
                const currentTimestamp = Date.now();
                localStorage.setItem('FCMTokenExpiry', currentTimestamp.toString());
                localStorage.setItem('topics', JSON.stringify(data.topics));
                specialOffersEnabled = true;
                freeGiftsEnabled = true;
                notificationsAreEnabled = true;
            }).catch((error) => Rollbar.error("Get notifications settings error:" + error));
    }

    $: if (notificationsEnabled) {
        areNotificationsEnabled();
    }
    $: if (specialOffersEnabled) {
        specialOffersEnabled = true;
        areNotificationsEnabled();
    } else {
        specialOffersEnabled = false;
        areNotificationsEnabled();
    }
    $: if (freeGiftsEnabled) {
        freeGiftsEnabled = true;
        areNotificationsEnabled();
    } else {
        freeGiftsEnabled = false;
        areNotificationsEnabled();
    }

    export function show() {
        settingsShown = !settingsShown;
    }

    onMount(async () => {
        if (await isSupported()) {
            if (typeof Notification === 'undefined') {
                notificationsNotSupported = true;
                showEditIcon = false;
                return;
            }
            if (permission === 'default') {
                await requestPermission();
            }
            if (permission === 'granted') {
                setTimeout(() => {
                    if (shouldGenerateNewToken()) {
                        generateNewToken();
                    }
                }, 5000);
            }
            if (permission === 'granted' || permission === 'default') {
                setTimeout(() => {
                    const topicsStorage = localStorage.getItem('topics');
                    if (topicsStorage) {
                        let topics = JSON.parse(topicsStorage);
                        if (permission === 'granted') {
                            if (topics.some(topic => topic === 'wsSt200')) specialOffersEnabled = true;
                            if (topics.some(topic => topic === 'wsFnFreeSub' || topic === 'wsStFreeSub')) freeGiftsEnabled = true;
                        }
                        areNotificationsEnabled();
                    }
                }, 1000);
            }
            if (permission === 'denied') {
                showEditIcon = false;
            }
        } else {
            console.log('FCM is not supported in this browser.');
            notificationsNotSupported = true;
            showEditIcon = false;
        }
    });
</script>
{#if showEditIcon}
    <button
            class="notificationToggle"
            on:click={show}
            transition:fade>
        <Fa icon={notificationsAreEnabled ? faBell : faBellSlash} fw size="1.15x"/>
    </button>
{/if}
{#if settingsShown}
    <div class="notificationSettings" transition:fade>
        <div class="notificationSettings__List">
            <div class="notificationSettings__Item {notificationsAreEnabled ? 'notificationsAreEnabled' : ''}">
                <input
                        type="checkbox"
                        id="notification-checkbox"
                        bind:checked={notificationsAreEnabled}
                        on:change={enableNotificationsAgain}/>
                <label for="notification-checkbox">
                    Enable Notifications
                </label>
            </div>
            <div class="notificationSettings__Item {specialOffersEnabled ? 'specialOffersEnabled' : ''}">
                <input
                        type="checkbox"
                        id="special-offers-checkbox"
                        bind:checked={specialOffersEnabled}/>
                <label for="special-offers-checkbox">
                    Special Offers
                </label>
            </div>
            <div class="notificationSettings__Item {freeGiftsEnabled ? 'freeGiftsEnabled' : ''}">
                <input
                        type="checkbox"
                        id="free-gifts-checkbox"
                        bind:checked={freeGiftsEnabled}/>
                <label for="free-gifts-checkbox">
                    Free Gifts
                </label>
            </div>
            <button
                    type="submit"
                    class="notification__Button notification__Button--Close"
                    on:click={closeSettings}>
                Close Settings
            </button>
        </div>
    </div>
{/if}
<style>
    .notificationToggle {
        width: 40px;
        height: 40px;
        position: fixed;
        will-change: transform;
        padding: 9px;
        border: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        background: white;
        border-radius: 50%;
        bottom: 70px;
        right: 20px;
        transition: 200ms;
        opacity: 1;
        z-index: 99980;
    }

    .notificationToggle:hover {
        color: white;
        background: black;
    }

    .notificationSettings {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        transition: 300ms;
        will-change: transform;
        z-index: 99999;
    }

    .notificationSettings .notificationSettings__List {
        transform: scale(1);
    }

    .notificationSettings__List {
        background: white;
        color: black;
        max-width: 500px;
        padding: 40px;
        margin: auto;
        overflow-y: auto;
        box-sizing: border-box;
        max-height: 100vh;
        transition: 200ms transform;
        will-change: transform;
        transform: scale(0.95);
    }

    .notificationSettings__Item {
        display: block;
        padding-left: 60px;
        margin-bottom: 20px;
    }

    .notificationSettings__Item input {
        display: none;
    }

    .notificationSettings__Item label {
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        display: block;
        position: relative;
    }

    .notificationSettings__Item.notificationsAreEnabled label::before,
    .notificationSettings__Item.specialOffersEnabled label::before,
    .notificationSettings__Item.freeGiftsEnabled label::before {
        background: #4CAF50;
    }

    .notificationSettings__Item label::before {
        content: "";
        display: block;
        left: -60px;
        background: #F44336;
        height: 20px;
        border-radius: 20px;
        width: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .notificationSettings__Item label::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #FFFFFF;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -58px;
        transition: 200ms;
    }

    .notificationSettings__Item input:checked + label::after {
        transform: translate(20px, -50%);
    }

    .notification__Button {
        padding: 15px 40px;
        display: block;
        background: white;
        color: black;
        white-space: nowrap;
        border: 0;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
        transition: 200ms;
    }

    .notification__Button--Close {
        background: black;
        color: white;
        margin: 40px 0 0 15px;
        padding: 15px 60px;
    }

    .notification__Button:hover {
        opacity: 0.6;
    }

    /*@media only screen and (max-width: 900px) {*/
    /*    .notification__Button {*/
    /*        margin: 0 10px 10px 0;*/
    /*    }*/

    /*    .notification__Button--Close {*/
    /*        margin: 40px 0 0;*/
    /*    }*/
    /*}*/
</style>