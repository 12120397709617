<script>
    import {_, number} from 'svelte-i18n';
    import Ribbon from "./Ribbon.svelte";
    import goldRushHeader from '../assets/gold_rush/banner.png?format=webp;jpg';
    import bottomGradient from '../assets/gold_rush/bottom_gradient.png?format=webp;jpg';
    import infoIcon from '../assets/gold_rush/info_icon.png?format=webp;jpg';
    import rowGlow from '../assets/gold_rush/row_glow.png?format=webp;jpg';
    import activeRowGlow from '../assets/gold_rush/active_row_glow.png?format=webp;jpg';
    import chestGlow from '../assets/gold_rush/chest_glow.png?format=webp;jpg';
    import tippy from 'svelte-tippy';
    import 'tippy.js/dist/tippy.css'; // optional
    import 'tippy.js/animations/shift-away-subtle.css';
    import Countdown from "./Countdown.svelte";
    import {Button} from "svelte-chota";
    import ItemLootChest from "./Items/ItemLootChest.svelte";
    import ItemsListing from "./ItemsListing.svelte";
    import {claim_gold_rush_route} from "../constants.js";
    import {resourceDetails} from "../globals.js";

    export let event = {};

    export let globals = {};

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;

    let recharge = checkRecharge(event.prizes, event.currentPrizeId, event.points);

    let processing = false;

    let headerImage = goldRushHeader;
    let footerImage = bottomGradient;
    let infoIconImage = infoIcon;
    let rowGlowImage = rowGlow;
    let activeRowGlowImage = activeRowGlow;
    let chestGlowImage = chestGlow;

    let collected = false;

    let days = convertPrizesToDays(event.prizes, event.currentPrizeId);

    async function collectGoldRush(prizeId) {
        try {
            processing = true;
            const response = await fetch(claim_gold_rush_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prizeId: prizeId,
                    eventId: event.id,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.text();
            // console.log(res)

            collected = true;

            let prizeKey = 'prize' + prizeId;
            if (!event.claimablePrizes[prizeKey]) {
                event.claimablePrizes[prizeKey] = {};
            }
            event.claimablePrizes[prizeKey].claimed = 1;

        } catch (error) {
            Rollbar.error("Error collecting gold rush " + error)
        } finally {
            processing = false;
        }
    }

    function redirectToDailyDeals() {
        // Select all the span elements within the element with class "tabs"
        let spans = document.querySelectorAll('.tabs span');

        // Loop through the span elements
        for (let i = 0; i < spans.length; i++) {
            let span = spans[i];

            // Example based on text content:
            if (span.textContent.includes($_('NewShopTabDailyDeals'))) {
                // Simulate a click on the span
                span.click();
                break;
            }
        }
    }

    function checkRecharge(prizes, currentPrizeId, points) {
        let prizeKey = "prize" + currentPrizeId;
        return !!(prizes[prizeKey] && prizes[prizeKey].pointsNeeded > points);
    }

    function convertPrizesToDays(prizes, currentPrizeId) {
        let days = [];
        for (let prize in prizes) {
            let prizeNumber = parseInt(prize.replace('prize', ''));
            days.push({
                id: prizeNumber,
                claimed: currentPrizeId >= prizeNumber,
                pointsNeeded: prizes[prize].pointsNeeded,
                items: prizes[prize].items,
                goldValue: prizes[prize].goldValue
            });
        }
        return days;
    }

    function checkPrize(day) {
        if (event.claimablePrizes['prize' + day.id] && event.claimablePrizes['prize' + day.id].claimed === 1) {
            event.prizes['prize' + day.id].items.forEach(item => {
                item.claimed = true;
            });
        }
        return event.prizes['prize' + day.id].items;
    }

    function claimUnclaimedDays() {
        for (let i = 0; i < Object.keys(event.prizes).length; i++) {
            if (event.claimablePrizes['prize' + (i + 1)] && event.claimablePrizes['prize' + (i + 1)].claimed === 0 && (i + 1) < event.currentPrizeId) {
                collectGoldRush(i + 1);
            }
        }
    }

    function checkIfCollected() {
        if (event.claimablePrizes['prize' + event.currentPrizeId] && event.claimablePrizes['prize' + event.currentPrizeId].claimed === 1) {
            collected = true;
        }
    }

    claimUnclaimedDays();
    checkIfCollected();
</script>

<div class="pack goldRush">
    <Ribbon>{$_('LimitedOfferPromoText')}!</Ribbon>
    <div class="header">
        <picture>
            <source srcset={headerImage[0]} type="image/webp">
            <source srcset={headerImage[1]} type="image/jpg">
            <img alt={$_('NewShopTabEventRecharge')} src={headerImage[0]}>
        </picture>
        <div class="headerBottom">
            <div class="title">{$_('NewShopTabEventRecharge')}</div>
            {#if event.howToEarnPoints.makePurchaseOnWebshop}
                <div class="description">{$_('RechargeRewardsDescriptionAnyPack')}</div>
            {:else}
                <div class="description">{$_('RechargeRewardsDescription')}</div>
            {/if}
            <picture>
                <source srcset={infoIconImage[0]} type="image/webp">
                <source srcset={infoIconImage[1]} type="image/jpg">
                {#if event.howToEarnPoints.makePurchaseOnWebshop}
                    <img alt={$_('RechargeRewardsInfo1AnyPack')} class="infoIcon" src={infoIconImage[0]}
                         use:tippy={{
                        content: $_('RechargeRewardsInfo1AnyPack').replace("1. ", ""),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                      }}>
                {:else}
                    <img alt={$_('ConversionPathInfo2')} class="infoIcon" src={infoIconImage[0]}
                         use:tippy={{
                        content: $_('ConversionPathInfo2'),
                        theme: 'shop',
                        boundary: 'scrollingContainer',
                        arrow: true,
                        flip: false,
                        placement: 'top-start',
                        animation: 'shift-away-subtle',
                      }}>
                {/if}
            </picture>
        </div>
    </div>
    <div class="content">
        <div class="daysDisplay">
            <div class="dayRowWrapper">
                {#each days as day}
                    <div class="dayRow {event.currentPrizeId === day.id ? 'current' : ''}">
                        {#if event.currentPrizeId === day.id}
                            <picture class="rowGlow activeRow">
                                <source srcset={activeRowGlowImage[0]} type="image/webp">
                                <source srcset={activeRowGlowImage[1]} type="image/jpg">
                                <img alt="Row Glow" src={activeRowGlowImage[0]}>
                            </picture>
                        {:else}
                            <picture class="rowGlow">
                                <source srcset={rowGlowImage[0]} type="image/webp">
                                <source srcset={rowGlowImage[1]} type="image/jpg">
                                <img alt="Row Glow" src={rowGlowImage[0]}>
                            </picture>
                        {/if}
                        <div class="dayNumber">
                            <span>{$_('Day')}</span>
                            <span>{day.id}</span>
                        </div>
                        {#if event.currentPrizeId === day.id}
                            <div class="itemWrapper chestWrapper">
                                <picture class="chestGlow">
                                    <source srcset={chestGlowImage[0]} type="image/webp">
                                    <source srcset={chestGlowImage[1]} type="image/jpg">
                                    <img alt="Chest Glow" src={chestGlowImage[0]}>
                                </picture>
                                {#if event.claimablePrizes['prize' + day.id] && event.claimablePrizes['prize' + day.id].claimed === 1}
                                    <ItemLootChest gameItem={{type: 'RechargeGoldChest' + day.id + '-open'}}
                                                   item={{amount: 1}}
                                    />
                                {:else}
                                    <ItemLootChest gameItem={{type: 'RechargeGoldChest' + day.id}}
                                                   item={{amount: 1}}
                                    />
                                {/if}
                            </div>
                        {:else}
                            {#if event.claimablePrizes['prize' + day.id] && event.claimablePrizes['prize' + day.id].claimed === 1}
                                <div class="itemWrapper chestWrapper">
                                    <ItemLootChest gameItem={{type: 'RechargeGoldChest' + day.id + '-open'}}
                                                   item={{amount: 1}}
                                    />
                                </div>
                            {:else}
                                <div class="itemWrapper chestWrapper">
                                    <ItemLootChest gameItem={{type: 'RechargeGoldChest' + day.id}}
                                                   item={{amount: 1}}
                                    />
                                </div>
                            {/if}
                        {/if}
                        {#if event.claimablePrizes['prize' + day.id] && event.claimablePrizes['prize' + day.id].claimed === 1}
                            {#if checkPrize(day)}
                                <ItemsListing globals={globals} goldAmount={0}
                                              items={event.prizes['prize' + (day.id)].items} storePack={{}}
                                              hasMask={false}/>
                            {/if}
                        {:else}
                            <ItemsListing globals={globals} goldAmount={0}
                                          items={event.prizes['prize' + (day.id)].items} storePack={{}}
                                          hasMask={false}/>
                        {/if}
                    </div>
                    {#if event.currentPrizeId === day.id}
                        {#if recharge}
                            {#if event.howToEarnPoints.makePurchaseOnWebshop}
                                <div class="infoRow recharge">
                                    {@html $_('RechargeRewardsCurrentDescriptionInProgressAnyPack')}
                                </div>
                            {:else}
                                <div class="infoRow recharge">
                                    {@html $_('RechargeRewardsCurrentChestDescriptionInProgress', {
                                        values: {
                                            GOLD_RECHARGED: $number(event.points),
                                            GOLD_REMAINING: $number(day.pointsNeeded - event.points),
                                            CHEST_VALUE: $number(day.goldValue),
                                        }
                                    }).replace(/\. /g, ".<br/>")}
                                </div>
                            {/if}
                        {:else}
                            {#if collected}
                                <div class="infoRow">
                                    {@html $_('RechargeRewardsCompletedChestTooltip').replace(/\. /g, ".<br/>")}
                                </div>
                            {:else}
                                <div class="infoRow">
                                    {@html $_('RechargeRewardsCurrentChestDescriptionCompleted').replace(/! /g, "!<br/>")}
                                </div>
                            {/if}
                        {/if}
                    {/if}
                {/each}
            </div>
        </div>
    </div>
    <picture class="footer-image">
        <source srcset={footerImage[0]} type="image/webp">
        <source srcset={footerImage[1]} type="image/jpg">
        <img alt={$_('NewShopTabEventRecharge')} src={footerImage[0]}>
    </picture>
    <div class="footer">
        {#if recharge}
            <Button class="claim-button orange" primary loading={processing}
                    on:click={redirectToDailyDeals}>
                {#if event.howToEarnPoints.makePurchaseOnWebshop}
                    {$_('RechargeRewardsButtonAnyPack')}
                {:else}
                    {$_('RechargeRewardsButton')}
                {/if}
            </Button>
            <Countdown endDate={event.finishAt}>
                {$_('IncrementalPacksTimeLeft')}
            </Countdown>
        {:else}
            {#if collected}
                <Button class="claim-button green" primary disabled>
                    {$_('Collected')}
                </Button>
                <span class="timer">{$_('SubscriptionPackCollectPopupInfo')}</span>
            {:else}
                {#if event.currentPrizeId >= 1}
                    <Button class="claim-button green" primary loading={processing}
                            on:click={collectGoldRush(event.currentPrizeId)}>
                        {$_('EventClaimButton')} {$_('Day')} {event.currentPrizeId}
                    </Button>
                {/if}
                <Countdown endDate={event.finishAt}>
                    {$_('IncrementalPacksTimeLeft')}
                </Countdown>
            {/if}
        {/if}
    </div>
</div>

<style lang="scss">
  :global {
    .grid.goldRush {
      @media screen and (min-width: 250px) {
        grid-auto-rows: 363px !important;
        grid-template-columns: repeat(auto-fit, minmax(246px, 246px)) !important;
      }

      @media screen and (min-width: 320px) {
        grid-auto-rows: 466px !important;
        grid-template-columns: repeat(auto-fit, minmax(316px, 316px)) !important;
      }

      @media screen and (min-width: 375px) {
        grid-auto-rows: 544px !important;
        grid-template-columns: repeat(auto-fit, minmax(369px, 369px)) !important;
      }

      @media screen and (min-width: 425px) {
        grid-auto-rows: 608px !important;
        grid-template-columns: repeat(auto-fit, minmax(412px, 412px)) !important;
      }

      @media screen and (min-width: 768px) {
        grid-auto-rows: 686px !important;
        grid-template-columns: repeat(auto-fit, minmax(465px, 465px)) !important;
      }

      @media screen and (min-width: 1024px) {
        grid-auto-rows: 711px !important;
        grid-template-columns: repeat(auto-fit, minmax(482px, 482px)) !important;
      }

      @media screen and (min-width: 2560px) {
        grid-auto-rows: 905px !important;
        grid-template-columns: repeat(auto-fit, minmax(614px, 614px)) !important;
      }
    }

    .pack {
      &.goldRush {
        width: 877px;
        height: 1294px;
        background: #240c06;
        border-radius: 20px;
        position: relative;

        @media screen and (min-width: 250px) {
          transform: scale(0.28);
          margin: -470px;
        }

        @media screen and (min-width: 320px) {
          transform: scale(0.36);
          margin: -420px;
        }

        @media screen and (min-width: 375px) {
          transform: scale(0.42);
          margin: -380px;
        }

        @media screen and (min-width: 425px) {
          transform: scale(0.47);
          margin: -340px;
        }

        @media screen and (min-width: 768px) {
          transform: scale(0.53);
          margin: -310px;
        }

        @media screen and (min-width: 1024px) {
          transform: scale(0.55);
          margin: -290px;
        }

        @media screen and (min-width: 2560px) {
          transform: scale(0.7);
          margin: -190px;
        }

        .ribbon-wrapper {
          width: 142px;
          height: 142px;
          left: -4px;
          top: -5px;

          span {
            font-size: 18px;
            width: 150px;
            padding: 0 25px;
            top: 21px;
            left: -35px;
            font-weight: 500;
          }
        }

        .header {
          top: 0;
          left: 0;
          right: 0;
          height: 432px;
          box-shadow: none;
          border: none;
          border-radius: 20px 20px 0 0;
          z-index: -1;

          .headerBottom {
            position: absolute;
            bottom: 45px;
            background-color: rgba(63, 29, 15, 0.65);
            width: 877px;
            height: 167px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding: 0 70px 10px 50px;

            .title {
              font-size: 64px;
              color: rgb(254, 255, 219);
              font-weight: bold;
              text-shadow: 0 0 8.16px rgba(0, 0, 0, 0.36);
              line-height: 1.2;
            }

            .description {
              font-size: 23px;
              color: rgb(255, 217, 87);
              line-height: 1.1;
            }

            .infoIcon {
              position: absolute;
              right: 15px;
              top: 20px;
            }
          }
        }

        .content {
          position: absolute;
          top: 387px;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .daysDisplay {
            position: relative;
            top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;
            border-radius: 10px;
            background-color: rgba(255, 227, 132);
            box-shadow: inset 0 0 76px 0 rgba(0, 0, 0, 0.47);
            width: 817px;
            height: 707px;

            .dayRowWrapper {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: 100%;

              .dayRow {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                position: relative;
                width: 613px;
                height: 120px;

                &.current {
                  .dayNumber {
                    color: rgb(255, 253, 199);
                  }
                }

                .rowGlow {
                  position: absolute;
                  bottom: -64px;
                  width: 816px;
                  height: 226px;

                  &.activeRow {
                    width: 819px;
                  }
                }

                .itemWrapper {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: relative;
                  flex-grow: 1;

                  &.chestWrapper {
                    margin-left: -35px;
                    bottom: 5px;

                    &:hover {
                      .chestGlow {
                        transform: translateZ(0) scale(1.2);
                      }
                    }

                    .item {
                      height: 144px;

                      .item-icon {
                        width: 144px;
                        height: 144px;
                        max-width: 144px;
                        max-height: 144px;
                      }
                    }

                    .item-amount {
                      display: none;
                    }

                    .RechargeGoldChest1::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest1.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest1-open::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest1-open.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest2::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest2.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest2-open::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest2-open.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest3::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest3.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest3-open::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest3-open.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest4::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest4.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest4-open::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest4-open.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest5::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest5.png');
                      background-size: cover;
                      padding: 0;
                    }

                    .RechargeGoldChest5-open::after {
                      content: '\00a0';
                      display: inline-block;
                      width: 144px;
                      max-width: 144px;
                      height: 144px;
                      background-image: url('/images/activityChests/activity-chest5-open.png');
                      background-size: cover;
                      padding: 0;
                    }
                  }

                  .chestGlow {
                    position: absolute;
                    bottom: -50px;
                    left: -30px;
                    width: 211px;
                    height: 204px;
                    transition: transform 0.15s ease-in;
                    backface-visibility: hidden;
                    transform: translateZ(0) scale(1);
                  }
                }

                .dayNumber {
                  font-size: 37px;
                  color: rgb(137, 85, 28);
                  font-weight: bold;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  text-align: left;
                  z-index: 1;
                  position: relative;
                  right: 50px;
                }

                .item {
                  height: 80px;
                  padding: 0;
                  border: none;
                  z-index: 1;
                  justify-content: flex-end;
                  position: relative;

                  .item-icon {
                    width: 80px;
                    height: 80px;
                    max-width: 80px;
                    max-height: 80px;

                    &:after {
                      max-width: 80px;
                    }
                  }

                  .item-title {
                    display: none;
                  }

                  .item-amount {
                    position: absolute;
                    bottom: 8px;
                    right: 15px;
                    height: auto;
                    color: #fff;
                    font-weight: normal;
                    text-shadow: 0.5px 0.866px 0 rgba(0, 0, 0, 0.39);
                  }
                }

                .items {
                  background: none;
                  margin: 0;

                  > div:first-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                  }
                }
              }

              .infoRow {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgb(117, 167, 11);
                width: 817px;
                height: 107px;
                font-size: 22px;
                color: rgb(225, 255, 133);
                font-weight: bold;
                text-align: center;
                text-shadow: 0.5px 0.866px 0 rgba(0, 0, 0, 0.39);
                line-height: 1.2;
                position: relative;

                &:after {
                  content: " ";
                  border-left: 8px solid transparent;
                  border-right: 8px solid transparent;
                  border-bottom: 8px solid rgb(117, 167, 11);
                  position: absolute;
                  top: -8px;
                  left: 220px;
                }

                &.recharge {
                  background-color: rgb(216, 107, 11);
                  color: rgb(255, 233, 133);

                  &:after {
                    border-bottom-color: rgb(216, 107, 11);
                  }
                }
              }
            }
          }
        }

        .footer-image {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 441px;
          z-index: -1;
        }

        .footer {
          position: absolute;
          bottom: 27px;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          button {
            width: 410px;
            height: 100px;
            font-size: 38px;
            margin-bottom: 15px;
          }

          .timer {
            font-size: 20px;
            color: rgb(255, 228, 133);
            font-weight: bold;
            line-height: normal;
          }
        }
      }
    }
  }
</style>