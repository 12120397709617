<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";
    import {get} from "svelte/store";
    import {itemRarity} from '../../constants';
    import {rechargeRewardsEvent} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    let baseName = getBaseName();
    let rarity = itemRarity[gameItem.rarity];

    function setItemDetails() {
        itemDetails.title = $_(getText('ItemTitle'));
        itemDetails.description = $_(getText('ItemDescription'));

        itemDetails.amount = item.amount;
        itemDetails.icon = getIcon();

        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    function getBaseName() {
        switch (gameItem.type) {
            case "halloween":
                return 'HalloweenLootChest';
            case "easterLootChest":
                return 'EasterLootChest';
            case "thanksgivingLootChest":
                return 'ThanksGivingLootChest';
            case "genericshopLootChest":
                return 'GenericShopLootChest';
            case "gettysburgLootChest":
                return 'GettysburgLootChest';
            case "stPatrickLootChest":
                return 'stPatrickLootChest';
            case "superWonderEventCommandersLootChest":
                return 'superWonderEventCommandersLootChest';
            case "fortsAssaultGeneric":
                return 'FortsAssaultGenericLootChest';
            case "RechargeGoldChest1":
                return 'RechargeGoldChest1';
            case "RechargeGoldChest1-open":
                return 'RechargeGoldChest1-open';
            case "RechargeGoldChest2":
                return 'RechargeGoldChest2';
            case "RechargeGoldChest2-open":
                return 'RechargeGoldChest2-open';
            case "RechargeGoldChest3":
                return 'RechargeGoldChest3';
            case "RechargeGoldChest3-open":
                return 'RechargeGoldChest3-open';
            case "RechargeGoldChest4":
                return 'RechargeGoldChest4';
            case "RechargeGoldChest4-open":
                return 'RechargeGoldChest4-open';
            case "RechargeGoldChest5":
                return 'RechargeGoldChest5';
            case "RechargeGoldChest5-open":
                return 'RechargeGoldChest5-open';
            default:
                return 'GenericShopLootChest';
        }
    }

    function getIcon() {
        switch (gameItem.type) {
            case "genericshopLootChest":
                return baseName + rarity + 'Item';
            case "thanksgivingLootChest":
                return "ThanksGivingLootChestItem";
            case "RechargeGoldChest1":
                return 'RechargeGoldChest1';
            case "RechargeGoldChest1-open":
                return 'RechargeGoldChest1-open';
            case "RechargeGoldChest2":
                return 'RechargeGoldChest2';
            case "RechargeGoldChest2-open":
                return 'RechargeGoldChest2-open';
            case "RechargeGoldChest3":
                return 'RechargeGoldChest3';
            case "RechargeGoldChest3-open":
                return 'RechargeGoldChest3-open';
            case "RechargeGoldChest4":
                return 'RechargeGoldChest4';
            case "RechargeGoldChest4-open":
                return 'RechargeGoldChest4-open';
            case "RechargeGoldChest5":
                return 'RechargeGoldChest5';
            case "RechargeGoldChest5-open":
                return 'RechargeGoldChest5-open';
            default:
                return baseName + "Item";
        }
    }

    function getText(suffix) {
        if (gameItem.type === "genericshopLootChest") {
            return baseName + rarity + suffix;
        } else if (
            gameItem.type === 'RechargeGoldChest1' ||
            gameItem.type === 'RechargeGoldChest2' ||
            gameItem.type === 'RechargeGoldChest3' ||
            gameItem.type === 'RechargeGoldChest4' ||
            gameItem.type === 'RechargeGoldChest5'
        ) {
            // Check if makePurchaseOnWebshop is true and return the appropriate tooltip
            if ($rechargeRewardsEvent.howToEarnPoints.makePurchaseOnWebshop) {
                return 'RechargeRewardsCurrentTooltipAnyPack';
            } else {
                return 'RechargeRewardsCurrentChestTooltip';
            }
        } else if (
            gameItem.type === 'RechargeGoldChest1-open' ||
            gameItem.type === 'RechargeGoldChest2-open' ||
            gameItem.type === 'RechargeGoldChest3-open' ||
            gameItem.type === 'RechargeGoldChest4-open' ||
            gameItem.type === 'RechargeGoldChest5-open'
        ) {
            return 'RechargeRewardsCompletedChestTooltip';
        } else {
            return baseName + suffix;
        }
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
