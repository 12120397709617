<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;
    export let globals;

    export let itemDetails = {};

    const speedUpDetails = () => {
        switch (gameItem.type) {
            case 'train':
                return {
                    title:
                        globals.formatTimeFriendly(gameItem.seconds) +
                        ' ' +
                        $_('TrainSpeedUpTitle'),
                    description: $_('TrainSpeedUpDescription', {
                        values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                    }),
                    icon: 'speedup_train',
                };

            case 'research':
                return {
                    title:
                        globals.formatTimeFriendly(gameItem.seconds) +
                        ' ' +
                        $_('ResearchSpeedUpTitle'),
                    description: $_('ResearchSpeedUpDescription', {
                        values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                    }),
                    icon: 'speedup_research',
                };

            case 'upgrade':
                return {
                    title:
                        globals.formatTimeFriendly(gameItem.seconds) +
                        ' ' +
                        $_('UpgradeSpeedUpTitle'),
                    description: $_('UpgradeSpeedUpDescription', {
                        values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                    }),
                    icon: 'speedup_construction',
                };

            case 'healTroops':
                return {
                    title:
                        globals.formatTimeFriendly(gameItem.seconds) +
                        ' ' +
                        $_('HealTroopsSpeedUpTitle'),
                    description: $_('HealTroopsSpeedUpDescription', {
                        values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                    }),
                    icon: 'speedup_heal',
                };

            default:
                if (
                    gameItem.seconds == 36000 ||
                    gameItem.seconds == 21600 ||
                    gameItem.seconds == 43200
                ) {
                    return {
                        title: $_('SpeedUpTitleMin', {
                            values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                        }),
                        description: $_('SpeedUpDescriptionMin', {
                            values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                        }),
                        icon: 'speedup_special',
                    };
                } else {
                    return {
                        title: $_('SpeedUpTitleMin', {
                            values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                        }),
                        description: $_('SpeedUpDescriptionMin', {
                            values: {TIME: globals.formatTimeFriendly(gameItem.seconds)},
                        }),
                        icon: 'speedup_l',
                    };
                }
        }
    };

    function setItemDetails() {
        itemDetails.title = speedUpDetails().title;
        itemDetails.description = speedUpDetails().description;
        itemDetails.icon = speedUpDetails().icon;
        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
