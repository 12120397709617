import yaml from 'js-yaml';

const cache = new Map();

export const getStorePackConfig = (url) => {

    if (cache.has(url)) {
        return Promise.resolve(cache.get(url));
    }

    return fetch(url, {
        method: 'GET',
    })
        .then((response) => response.text())
        .then((file) => {
            const types = {};

            // remove the unity tag line
            file = file.replace(/%TAG.+\r?\n?/, '');
            file = file.replace(/!u!([0-9]+).+/g, '');

            // create our schema    
            const schema = yaml.DEFAULT_SCHEMA.extend(Object.values(types));
            let config = yaml.load(file, null, schema);

            cache.set(url, config.MonoBehaviour);
            return config.MonoBehaviour;

        })
        .catch((error) => {
            console.error(error);
            Rollbar.error("Get store pack config error: " + error)
        })
}
