<script>
    import {_, locale} from 'svelte-i18n';
    import {capitalizeFirstLetter} from '../../utils';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function getTokenDetails() {
        let details = {};

        let tokenType = gameItem.type ? gameItem.type : '';
        let tokenRarity = gameItem.tokenRarity ? gameItem.tokenRarity : '';

        let eventTheme = gameItem.event ? gameItem.event : 'generic';

        if (eventTheme === 'fathersday') {
            eventTheme = 'fathersDay';
        }

        let eventThemeTranslation = eventTheme === 'stPatrick' ? eventTheme : capitalizeFirstLetter(eventTheme);

        details.icon = gameItem.category.toLowerCase() + '_' + tokenType.toLowerCase() + (tokenRarity !== '' ? '_' + tokenRarity.toLowerCase() : '') + '_' + eventTheme.toLowerCase();
        details.title = $_(
            capitalizeFirstLetter(gameItem.category) +
            capitalizeFirstLetter(tokenType) +
            capitalizeFirstLetter(tokenRarity) +
            eventThemeTranslation +
            'Title',
        );
        details.description = $_(
            capitalizeFirstLetter(gameItem.category) +
            capitalizeFirstLetter(tokenType) +
            capitalizeFirstLetter(tokenRarity) +
            eventThemeTranslation +
            'Description',
        );

        return details;
    }

    function setItemDetails() {
        const tokenDetails = getTokenDetails();

        itemDetails.title = tokenDetails.title;
        itemDetails.description = tokenDetails.description;
        itemDetails.icon = tokenDetails.icon;
        itemDetails.amount = item.amount;
        itemDetails.rarity = gameItem.rarity ? gameItem.rarity : 1;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
