export function getPackRarity(rarity) {
    switch (rarity) {
        case 1:
            return 'common';
        case 2:
            return 'rare';
        case 3:
            return 'epic';
        case 4:
            return 'legendary';
        default:
            return 'common';
    }
}

export const resourceDetails = {
    1: {
        name: 'StoneResource',
        icon: 'stone_l',
    },
    2: {
        name: 'WoodResource',
        icon: 'wood_l',
    },
    3: {
        name: 'IronResource',
        icon: 'iron_l',
    },
    4: {
        name: 'FoodResource',
        icon: 'food_l',
    },
    5: {
        name: 'SilverResource',
        icon: 'silver_l',
    },

    6: {
        name: 'Gold',
    },
    7: {
        name: 'Items',
    },
};

export const researchTypes = [
    "",
    "constructionSpeed",
    "stoneProduction",
    "foodProduction",
    "ironProduction",
    "troopLoad",
    "woodProduction",
    "marchSpeed",
    "gatheringSpeed",
    "goldGathering",
    "scouting",
    "trainingSpeed",
    "infantryAttack",
    "rangedAttack",
    "cavalryAttack",
    "infantryDefense",
    "rangedDefense",
    "cavalryDefense",
    "pikemen",
    "longbowmen",
    "heavyCavalry",
    "ballista",
    "siegeAttack",
    "siegeDefense",
    "armoredSwordsmen",
    "crossbowmen",
    "templars",
    "catapult",
    "troopHealth",
    "troopDefense",
    "royalGuard",
    "musketeers",
    "warElephants",
    "trebuchet",
    "advancedVault",
    "gatheringMarchSpeed",
    "monsterMarchSpeed",
    "troopAttack",
    "marchSlot",
    "equipmentSlot",
    "equipmentSkillPreset",
    "marchPreset",
    "troopDefenseDebuff",
    "troopHealthDebuff",
    "troopAttackDebuff",
    "wonderRallyCapacity",
    "attackRallyCapacity",
    "unlockSupplySlot",
    "suppliesActiveTime",
    "troopAttack2",
    "troopDefense2",
    "troopHealth2",
    "equipmentSlot2",
    "gatheringMarchSpeed2",
    "monsterMarchSpeed2",
    "rallyMarchSpeedDebuff2",
    "troopAttackDebuff2",
    "troopHealthDebuff2",
    "troopDefenseDebuff2",
    "unlockSupplySlot2",
    "suppliesActiveTime2",
    "cavalryHealth",
    "cavalryHealthDebuff",
    "cavalryTier5",
    "infantryHealth",
    "infantryHealthDebuff",
    "infantryTier5",
    "rangedHealth",
    "rangedHealthDebuff",
    "rangedTier5",
    "siegeTier5"
];

export const researchTypesIcons = [
    "",
    "research_construction_speed",
    "research_cotton_production",
    "research_food_production",
    "research_iron_production",
    "research_troop_load",
    "research_wood_production",
    "research_march_speed",
    "research_gathering_speed",
    "research_gold_gathering_speed",
    "research_military_scouting",
    "research_military_training_speed",
    "research_infantry_attack",
    "research_field_artillery_attack",
    "research_cavalry_attack",
    "research_infantry_defense",
    "research_field_artillery_defense",
    "research_cavalry_defense",
    "research_unlock_infantry_t2",
    "research_unlock_field_artillery_t2",
    "research_unlock_cavalry_t2",
    "research_unlock_siege_t2",
    "research_siege_attack",
    "research_siege_defense",
    "research_unlock_infantry_t3",
    "research_unlock_field_artillery_t3",
    "research_unlock_cavalry_t3",
    "research_unlock_siege_t3",
    "research_troop_health",
    "research_troop_defense",
    "research_unlock_infantry_t4",
    "research_unlock_field_artillery_t4",
    "research_unlock_cavalry_t4",
    "research_unlock_siege_t4",
    "research_advanced_vault",
    "research_gathering_march_speed",
    "research_monster_march_speed",
    "research_troop_attack",
    "research_unlock_march_slot",
    "research_equipment_slot",
    "research_commander_preset",
    "research_march_preset",
    "research_enemy_defense_debuff",
    "research_enemy_health_debuff",
    "research_enemy_attack_debuff",
    "research_wonder_rally_capacity",
    "research_attack_rally_capacity",
    "research_equipment_slot",
    "research_supply_active_time",
    "research_troop_attack",
    "research_troop_defense",
    "research_troop_health",
    "research_equipment_slot",
    "research_gathering_march_speed",
    "research_monster_march_speed",
    "research_rally_march_speed_debuff",
    "research_enemy_attack_debuff",
    "research_enemy_health_debuff",
    "research_enemy_defense_debuff",
    "research_equipment_slot",
    "research_supply_active_time",
    "research_cavalry_health",
    "research_cavalry_health_debuff",
    "research_unlock_cavalry_t5",
    "research_infantry_health",
    "research_infantry_health_debuff",
    "research_unlock_infantry_t5",
    "research_field_artillery_health",
    "research_field_artillery_health_debuff",
    "research_unlock_field_artillery_t5",
    "research_unlock_siege_t5"
];