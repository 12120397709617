<script>
    import {_} from 'svelte-i18n';
    import {onMount} from "svelte";
    import Fa from 'svelte-fa/src/fa.svelte';
    import {faXmark} from '@fortawesome/free-solid-svg-icons';
    import {getAnalytics, logEvent} from 'firebase/analytics';
    import {logMetric} from "../metrics";
    import {Button, Card} from 'svelte-chota';
    import push from 'push.js';


    export let settings_modal_hide;
    let are_notifications_enabled = push.Permission.has();
    let push_notifications_topics = [];

    const analytics = getAnalytics();

    const notificationPermission = async () => {
        if (push.Permission.get() === 'granted') {
            return true;
        } else if (push.Permission.get() !== 'denied') {
            push.Permission.request(() => {
                console.log('Permission Granted');
                logMetric({notification_permission: 'granted'})
            }, () => {
                console.log('Permission Denied');
                logMetric({notification_permission: 'denied'})
            });
        }

        return false;
    };


    onMount(async () => {
        await logMetric({show_settings: "true"});

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'settings_modal',
            firebase_screen_class: 'web',
        });
    });
</script>

<Card class="settings-modal">
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a class="close-button" href="javascript:" on:click={settings_modal_hide}>
        <Fa fw icon={faXmark} primaryColor="#240c05" size="2x"/>
    </a>
    <h1>{$_('Settings')}</h1>
    <h4>Active push notifications</h4>
    <!--    <ul>-->
    <!--        <li>-->
    <!--            <label>-->
    <!--                &lt;!&ndash;                <input {are_notifications_enabled ? undefined : 'disabled'} type="checkbox">&ndash;&gt;-->
    <!--                Special offers-->
    <!--            </label>-->
    <!--        </li>-->
    <!--        <li>-->
    <!--            <label>-->
    <!--                &lt;!&ndash;                <input {are_notifications_enabled ? undefined : 'disabled'} type="checkbox">&ndash;&gt;-->
    <!--                Free gifts-->
    <!--            </label>-->
    <!--        </li>-->
    <!--    </ul>-->
    <Button
            class="claim-button green"
            primary
    >
        {$_('Save')}
    </Button>
</Card>

<style lang="scss">
  @import '../styles/vars.scss';

  :global {
    .settings-modal {
      &.modal {
        min-width: 450px !important;
      }

      @media (max-width: 700px) {
        &.modal {
          min-width: 350px !important;
        }
      }

      &.card {
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: left;
        overflow: hidden;
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
        transition: all 0.2s ease-in;
        position: relative;

        li {
          list-style: none;
          margin: 0;
          padding: 0;
          padding-left: 5px;
          font-size: 0.9em;
          color: $dark-color;

          label {
            display: flex;
            align-items: center;

            input {
              margin-right: 6px;
            }
          }
        }


        h1 {
          font-weight: 700;
          font-family: var(--font-family-sans-condensed);
          padding: 1rem 2rem;
          line-height: 1.2em;
          margin-bottom: 0;
          margin-top: 0;
          text-align: center;
        }

        h4 {
          font-family: var(--font-family-sans-condensed);
          max-width: 400px;
          font-size: 16px;
          color: #8c7138;
          font-weight: bold;
          border-bottom: 1px solid #8c7138;
        }

        .claim-button, .claimed-mark {
          margin: 20px 0;
        }

        .items {
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 6px;
          padding: 10px;
          margin: 0;
          width: auto;
        }

        .close-button {
          position: absolute;
          top: 0;
          right: 0;
          padding: 7px 2px;
        }
      }

    }
  }
</style>